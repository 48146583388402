import axios from "axios";

import Swalert from "./../../../components/Swalert";
import { config } from "../../StaticString";
import JWToken from "./../../JWToken";

const baseUrl = config.baseUrl + "master/users/";

const _read = async (payload) => {
  return await actionPost(payload, baseUrl + "read");
};
const _update = async (payload) => {
  return await actionPost(payload, baseUrl + "update");
};
const _reset = async (payload) => {
  return await actionPost(payload, baseUrl + "reset");
};
const _locked = async (payload) => {
  return await actionPost(payload, baseUrl + "locked");
};

const actionPost = async (payload, urlBackend) => {
  try {
    payload.append("token", new JWToken()._getValueJWToken());
    const req = await axios.post(urlBackend, payload);
    const response = req.data;
    new JWToken()._setJWToken(response.jwt.token, response.jwt.expired);
    return response.values;
  } catch (e) {
    const swal = await new Swalert().error(e + "\n" + e.response.data.error);
    if (swal) {
      return { status: false, data: e.response.data.error };
    }
  }
};

const users = { _read, _update, _reset, _locked };
export { users };
