import { createRef, useEffect, useState } from "react";
import AccessMenu from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import FormData from "form-data";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { pageMenu } from "../../utils/StaticString";
import Icon from "../../components/Icon";
import Tables from "../../components/Tables";
import Swalert from "../../components/Swalert";
import Div from "../../components/Div";
import SelectLabel from "../../components/SelectLabel";
import JWToken from "../../utils/JWToken";

function Access() {
  const { actCreated, actUpdate, actDelete } = AccessMenu();

  const [loader, setLoader] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [listMenu, setListMenu] = useState([]);

  const [modalAccess, setModalAccess] = useState(false);
  const [optionsAccess, setOptionsAccess] = useState("");
  const [defaultAccess, setDefaultAccess] = useState("");

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [idAccess, setIdAccess] = useState("0");
  const [accessName, setAccessName] = useState("");
  const [menuCheckAll, setMenuCheckAll] = useState(false);

  const refFrom = createRef();

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];

      if (typeof x === "string") {
        x = ("" + x).toLowerCase();
      }
      if (typeof y === "string") {
        y = ("" + y).toLowerCase();
      }

      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.master.access._read(payload);
    setLoader(false);
    if (result.status) {
      result.data.menu.map((item) => {
        if (parseInt(item.urut) < 10) {
          item.urut = "10000" + item.urut;
        } else if (parseInt(item.urut) < 100) {
          item.urut = "1000" + item.urut;
        } else if (parseInt(item.urut) < 1000) {
          item.urut = "100" + item.urut;
        } else if (parseInt(item.urut) < 10000) {
          item.urut = "10" + item.urut;
        }
        item.checked = false;
        item.permission = [];
      });

      setListMenu(result.data.menu);
      setListTable(result.data.access);

      const listAccess = result.data.access;
      const listAccessOptions = [];
      for (let index = 0; index < listAccess.length; index++) {
        const item = listAccess[index];
        const newItem = { value: item.id, label: item.accessName };
        listAccessOptions.push(newItem);
        if (item.id === result.data.organization.defaultAccess) {
          setDefaultAccess(newItem);
        }
      }

      setOptionsAccess(listAccessOptions);
    }
  };

  function setLayoutTable() {
    function action(item) {
      let disableButton = true;
      let outlineButton = "outline";
      if (item.accessName !== "Developer") {
        outlineButton = "outline-danger";
        disableButton = false;
      }

      return (
        <Button
          disabled={disableButton}
          onClick={() => deleteItem(item)}
          variant={outlineButton}
          size="sm"
          style={{ padding: "5px 5px 5px 15px" }}
        >
          <i
            key={randNumb()}
            className="material-icons"
            style={{ fontSize: "20px" }}
          >
            delete
          </i>
        </Button>
      );
    }
    function setTableMenu(jsonMenu) {
      const styleMenu = {
        backgroundColor: "#ffffff00",
        textAlign: "left",
        padding: "1px",
      };
      return jsonMenu.map((access) => {
        return listMenu.map((item) => {
          if (parseInt(item.id) === access.id) {
            if (
              item.path !== pageMenu.d_user &&
              item.path !== pageMenu.d_home
            ) {
              access.permission.sort();
              return (
                <tr style={styleMenu}>
                  <td style={styleMenu}>{item.path}</td>
                  <td style={styleMenu}>
                    <Icon
                      addClass=" text-success"
                      style={{ fontSize: "18px", marginLeft: "3px" }}
                    >
                      visibility
                    </Icon>
                    {access.permission.map((idCrud) => {
                      if (idCrud === 0) {
                        return (
                          <Icon
                            addClass=" text-primary"
                            style={{ fontSize: "18px", marginLeft: "3px" }}
                          >
                            add_circle
                          </Icon>
                        );
                      } else if (idCrud === 1) {
                        return (
                          <Icon
                            addClass=" text-warning"
                            style={{ fontSize: "18px", marginLeft: "3px" }}
                          >
                            edit
                          </Icon>
                        );
                      } else {
                        return (
                          <Icon
                            addClass=" text-danger"
                            style={{ fontSize: "18px", marginLeft: "3px" }}
                          >
                            delete
                          </Icon>
                        );
                      }
                    })}
                  </td>
                </tr>
              );
            }
          }
        });
      });
    }
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        no++;
        return (
          <>
            <Tables.Tr>
              <Tables.Td>{no}</Tables.Td>
              <Tables.Td
                style={{ cursor: "pointer" }}
                onClick={() => openModal(item)}
              >
                {item.accessName}
              </Tables.Td>
              <Tables.Td
                style={{ cursor: "pointer", textAlign: "left" }}
                onClick={() => openModal(item)}
              >
                <Table>
                  <Tables.Body>{setTableMenu(item.jsonMenu)}</Tables.Body>
                </Table>
              </Tables.Td>
              {actDelete ? <Tables.Td>{action(item)}</Tables.Td> : ""}
            </Tables.Tr>
          </>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>MENU</Tables.Th>
            {actDelete ? <Tables.Th>ACTION</Tables.Th> : ""}
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  function setLayoutModal() {
    function chekedItemRead(e, itemChecked) {
      const checked = e.target.checked;
      itemChecked.checked = checked;
      listMenu.map((item) => {
        if (item.id === itemChecked.id) {
          item = itemChecked;
          if (!checked) {
            item.permission = [];
          }
        }
      });
      setListMenu(listMenu);
    }
    function checkedAllItemRead(e) {
      const checked = e.target.checked;
      listMenu.map((item) => {
        item.checked = checked;
        if (accessName === "Developer") {
          if (
            item.path === pageMenu.d_pages ||
            item.path === pageMenu.m_access
          ) {
            item.checked = true;
          } else {
            if (!checked) {
              item.permission = [];
            }
          }
        } else {
          if (item.path === pageMenu.d_pages) {
            item.checked = false;
          }
          if (!checked) {
            item.permission = [];
          }
        }
      });
      setListMenu(listMenu);
      setMenuCheckAll(checked);
    }

    function chekedItemAccess(e, itemAcc, access) {
      if (!itemAcc.checked) {
        e.target.checked = false;
      } else {
        const checked = e.target.checked;
        const array = itemAcc.permission;
        if (checked) {
          array.push(access);
        } else {
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (access === element) {
              array.splice(index, 1);
            }
          }
        }
        listMenu.map((item) => {
          if (item.id === itemAcc.id) {
            item.permission = array;
          }
        });
        setListMenu(listMenu);
      }
    }
    function dataBody() {
      //const listSort = sortByKey(listMenu, "path");
      return listMenu.map((item) => {
        if (item.path !== pageMenu.d_user && item.path !== pageMenu.d_home) {
          let layoutRead = (
            <InputGroup.Checkbox
              className="form-check-input-success"
              defaultChecked={item.checked}
              onChange={(e) => chekedItemRead(e, item)}
            />
          );

          let layoutCreated = (
            <InputGroup.Checkbox
              className="form-check-input-primary"
              defaultChecked={false}
              onChange={(e) => chekedItemAccess(e, item, 0)}
            />
          );
          let layoutUpdate = (
            <InputGroup.Checkbox
              className="form-check-input-warning"
              defaultChecked={false}
              onChange={(e) => chekedItemAccess(e, item, 1)}
            />
          );
          let layoutDelete = (
            <InputGroup.Checkbox
              className="form-check-input-danger"
              defaultChecked={false}
              onChange={(e) => chekedItemAccess(e, item, 2)}
            />
          );

          item.permission.sort();
          item.permission.map((eccessId) => {
            if (eccessId === 0) {
              layoutCreated = (
                <InputGroup.Checkbox
                  className="form-check-input-primary"
                  defaultChecked={true}
                  onChange={(e) => chekedItemAccess(e, item, eccessId)}
                />
              );
            } else if (eccessId === 1) {
              layoutUpdate = (
                <InputGroup.Checkbox
                  className="form-check-input-warning"
                  defaultChecked={true}
                  onChange={(e) => chekedItemAccess(e, item, eccessId)}
                />
              );
            } else if (eccessId === 2) {
              layoutDelete = (
                <InputGroup.Checkbox
                  className="form-check-input-danger"
                  defaultChecked={true}
                  onChange={(e) => chekedItemAccess(e, item, eccessId)}
                />
              );
            }
          });

          if (accessName === "Developer") {
            if (
              item.path === pageMenu.d_pages ||
              item.path === pageMenu.m_access
            ) {
              layoutRead = (
                <InputGroup.Checkbox
                  className="form-check-input-success"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutCreated = (
                <InputGroup.Checkbox
                  className="form-check-input-primary"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutUpdate = (
                <InputGroup.Checkbox
                  className="form-check-input-warning"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutDelete = (
                <InputGroup.Checkbox
                  className="form-check-input-danger"
                  defaultChecked={true}
                  disabled
                />
              );
            }

            return (
              <Tables.Tr key={randNumb()}>
                <Tables.Td>{item.label}</Tables.Td>
                <Tables.Td>{item.path}</Tables.Td>
                <Tables.Td>{layoutRead}</Tables.Td>
                <Tables.Td>{layoutCreated}</Tables.Td>
                <Tables.Td>{layoutUpdate}</Tables.Td>
                <Tables.Td>{layoutDelete}</Tables.Td>
              </Tables.Tr>
            );
          } else {
            if (item.path !== pageMenu.d_pages) {
              return (
                <Tables.Tr key={randNumb()}>
                  <Tables.Td>{item.label}</Tables.Td>
                  <Tables.Td>{item.path}</Tables.Td>
                  <Tables.Td>{layoutRead}</Tables.Td>
                  <Tables.Td>{layoutCreated}</Tables.Td>
                  <Tables.Td>{layoutUpdate}</Tables.Td>
                  <Tables.Td>{layoutDelete}</Tables.Td>
                </Tables.Tr>
              );
            }
          }
        }
      });
    }
    function changeAccessName(e) {
      const newName = e.target.value;
      if (newName === "Developer") {
        setModal(false);
        new Swalert().warning("Nama Access sudah ada.");
      } else {
        setAccessName(e.target.value);
      }
    }
    return (
      <>
        <Modal
          show={modalAccess}
          onHide={() => setModalAccess(false)}
          backdrop="static"
          keyboard={false}
        >
          <Div>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SelectLabel
                title={"Default Access New User"}
                isSearchable={false}
                defaultValue={defaultAccess}
                onChange={(e) => {
                  updateDefaultAccess(e);
                }}
                options={optionsAccess}
              />
            </Modal.Body>
          </Div>
        </Modal>
        <Modal
          show={modal}
          onHide={() => setModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Div>
            <Modal.Header closeButton>
              <Modal.Title>
                <b>{modalTitle}</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form ref={refFrom}>
                <Form.Group className="mb-3">
                  <Form.Label>Access Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly={accessName === "Developer" ? true : false}
                    value={accessName}
                    placeholder="Enter Access Name"
                    onChange={changeAccessName}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Tables.Table>
                    <Tables.Head>
                      <Tables.Tr>
                        <Tables.Th>LABEL</Tables.Th>
                        <Tables.Th>PATH</Tables.Th>
                        <Tables.Th>
                          <ToggleButton
                            id={randNumb()}
                            type="checkbox"
                            variant="outline-success"
                            checked={menuCheckAll}
                            onChange={(e) => checkedAllItemRead(e)}
                            style={{
                              padding: "2px",
                              height: "24px",
                              width: "30px",
                            }}
                          >
                            <Icon
                              style={{
                                fontSize: "18px",
                                marginLeft: "3px",
                                marginRight: "3px",
                                lineHeight: "15px",
                              }}
                            >
                              visibility
                            </Icon>
                          </ToggleButton>
                        </Tables.Th>
                        <Tables.Th>
                          <ToggleButton
                            variant="outline-primary"
                            style={{
                              cursor: "text",
                              padding: "2px",
                              height: "24px",
                              width: "30px",
                            }}
                          >
                            <Icon
                              style={{
                                fontSize: "18px",
                                marginLeft: "3px",
                                marginRight: "3px",
                                lineHeight: "15px",
                              }}
                            >
                              add_circle
                            </Icon>
                          </ToggleButton>
                        </Tables.Th>
                        <Tables.Th>
                          <ToggleButton
                            variant="outline-warning"
                            style={{
                              cursor: "text",
                              padding: "2px",
                              height: "24px",
                              width: "30px",
                            }}
                          >
                            <Icon
                              style={{
                                fontSize: "18px",
                                marginLeft: "3px",
                                marginRight: "3px",
                                lineHeight: "15px",
                              }}
                            >
                              edit
                            </Icon>
                          </ToggleButton>
                        </Tables.Th>
                        <Tables.Th>
                          <ToggleButton
                            variant="outline-danger"
                            style={{
                              cursor: "text",
                              padding: "2px",
                              height: "24px",
                              width: "30px",
                            }}
                          >
                            <Icon
                              style={{
                                fontSize: "18px",
                                marginLeft: "3px",
                                marginRight: "3px",
                                lineHeight: "15px",
                              }}
                            >
                              delete
                            </Icon>
                          </ToggleButton>
                        </Tables.Th>
                      </Tables.Tr>
                    </Tables.Head>
                    <Tables.Body>{dataBody()}</Tables.Body>
                  </Tables.Table>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={submitHandler}>
                Simpan
              </Button>
            </Modal.Footer>
          </Div>
        </Modal>
      </>
    );
  }

  function openModal(item) {
    if (!actUpdate) {
      return;
    }
    if (item === "") {
      setModalTitle("Tambah Access");
      setIdAccess("0");
      setAccessName("");
      listMenu.map((item) => {
        item.checked = false;
        item.permission = [];
      });
      setMenuCheckAll(false);
    } else {
      setMenuCheckAll(true);
      setModalTitle("Edit Access");
      setIdAccess(item.id);
      setAccessName(item.accessName);
      const jsonMenu = item.jsonMenu;
      listMenu.map((item) => {
        item.checked = false;
        item.permission = [];
        jsonMenu.map((access) => {
          if (parseInt(item.id) === access.id) {
            item.checked = true;
            item.permission = access.permission;
          }
        });

        if (item.accessName !== "Developer") {
          if (item.path !== pageMenu.d_pages) {
            if (!item.checked) {
              setMenuCheckAll(false);
            }
          }
        } else {
          if (!item.checked) {
            setMenuCheckAll(false);
          }
        }
      });
    }
    setModal(true);
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm(
      "Delete " + item.accessName,
      "Yes"
    );
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      const result = await Service.master.access._delete(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === item.id) {
            listTable.splice(index, 1);
            setListTable(listTable);
            return new Swalert().success(
              "Delete " + item.accessName + " Success"
            );
          }
        }
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  async function submitHandler() {
    const form = refFrom.current;
    if (accessName === "") {
      await new Swalert().warning("Access name harus di isi.");
      window.setTimeout(function () {
        form[0].focus();
      }, 500);
    } else {
      async function addData() {
        const menu = [];
        const listSort = sortByKey(listMenu, "urut");
        listSort.map((item) => {
          if (item.path === pageMenu.d_user || item.path === pageMenu.d_home) {
            menu.push({ id: parseInt(item.id), permission: [] });
          } else if (item.checked) {
            item.permission.sort();
            menu.push({ id: parseInt(item.id), permission: item.permission });
          }
        });
        setLoader(true);
        const payload = new FormData();
        payload.append("accessName", accessName);
        payload.append("jsonMenu", JSON.stringify(menu));
        const result = await Service.master.access._created(payload);
        setLoader(false);
        if (result.status) {
          setModal(false);
          const newData = {
            id: result.data.id,
            accessName: accessName,
            jsonMenu: menu,
          };
          listTable.push(newData);

          return new Swalert().success("Add " + accessName + " Success");
        } else {
          await new Swalert().warning(result.data);
          window.setTimeout(function () {
            form[0].focus();
          }, 500);
        }
      }
      async function editData() {
        const menu = [];
        const listSort = sortByKey(listMenu, "urut");
        listSort.map((item) => {
          if (item.path === pageMenu.d_user || item.path === pageMenu.d_home) {
            menu.push({ id: parseInt(item.id), permission: [] });
          } else if (item.checked) {
            item.permission.sort();
            menu.push({ id: parseInt(item.id), permission: item.permission });
          }
        });
        setLoader(true);
        const payload = new FormData();
        payload.append("id", idAccess);
        payload.append("accessName", accessName);
        payload.append("jsonMenu", JSON.stringify(menu));
        const result = await Service.master.access._update(payload);
        setLoader(false);
        if (result.status) {
          setModal(false);
          const newData = {
            id: idAccess,
            accessName: accessName,
            jsonMenu: menu,
          };

          for (let i = 0; i < listTable.length; i++) {
            const el = listTable[i];
            if (el.id === idAccess) {
              listTable[i] = newData;
            }
          }

          setListTable(listTable);
          return new Swalert().success("Update " + accessName + " Success");
        } else {
          await new Swalert().warning(result.data);
          window.setTimeout(function () {
            form[0].focus();
          }, 500);
        }
      }
      if (idAccess === "0") {
        addData();
      } else {
        editData();
      }
    }
  }

  async function updateDefaultAccess(e) {
    setDefaultAccess(e);

    setLoader(true);
    const payload = new FormData();
    payload.append("access", e.value);
    const result = await Service.master.access._default(payload);
    setLoader(false);
    if (result.status) {
      setModalAccess(false);
      return new Swalert().success("Update Default Access Success");
    }
  }

  return (
    <Layout loader={false}>
      {actCreated ? (
        <Div style={{ margin: "10px" }}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => openModal("")}
          >
            Tambah Menu
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            variant="outline-primary"
            size="sm"
            onClick={() => setModalAccess(true)}
          >
            User First Access
          </Button>
        </Div>
      ) : (
        ""
      )}
      {setLayoutTable()}
      {setLayoutModal()}
    </Layout>
  );
}
export default Access;
