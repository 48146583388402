import { useCallback, useState } from "react";

import Cropper from "react-easy-crop";
import getCroppedImg from "./../../components/cropped/Crop";
import { Form } from "react-bootstrap";

const EasyCrop = ({ image, rHeight, rWidth, refCrop, clickSave }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const blobImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      clickSave({ status: true, response: blobImage });
    } catch (e) {
      clickSave({ status: false, response: e });
    }
  }, [croppedAreaPixels, rotation, image]);

  return (
    <div>
      <button ref={refCrop} onClick={showCroppedImage} hidden />
      <div
        style={{
          paddingBottom: "100px",
          position: "relative",
          border: "1px",
          borderStyle: "solid",
        }}
      >
        <div style={{ minHeight: "300px", minWidth: "300px" }}>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={rHeight / rWidth}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "800px",
            position: "absolute",
          }}
        ></div>
      </div>

      <div>
        <br></br>
        <label>Rotate</label>
        <Form.Range
          value={rotation}
          min={0}
          max={360}
          step={0.1}
          onChange={(e) => setRotation(e.target.value)}
        />
        <label>Zoom</label>
        <Form.Range
          value={zoom}
          min={1}
          max={3}
          step={0.01}
          onChange={(e) => setZoom(e.target.value)}
        />
      </div>
    </div>
  );
};

export default EasyCrop;
