import { Form } from "react-bootstrap";

const InputLabel = ({ title, type, ...rest }) => {
  if (type === undefined) {
    type = "text";
  }
  return (
    <Form.Group className="mb-4">
      {title !== undefined ? <Form.Label>{title}</Form.Label> : ""}
      <Form.Control type={type} placeholder={title} {...rest} />
      <Form.Text
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default InputLabel;
