import { createRef, useEffect, useState } from "react";
import Access from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import { Button, Form, Modal } from "react-bootstrap";
import Icon from "../../components/Icon";
import Tables from "../../components/Tables";
import Div from "../../components/Div";
import InputLabel from "../../components/InputLabel";
import Swalert from "../../components/Swalert";
import DateMoment from "../../utils/DateMoment";
import JWToken from "../../utils/JWToken";

function User() {
  const { actCreated, actUpdate, actDelete } = Access();

  const [loader, setLoader] = useState(false);
  const [listTable, setListTable] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [idServer, setIdServer] = useState("");

  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [domain, setDomain] = useState("");
  const [path, setPath] = useState("");
  const [screet, setScreet] = useState("");
  const [expired, setExpired] = useState("");

  const refFrom = createRef();

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.master.server._read(payload);
    setLoader(false);
    if (result.status) {
      setListTable(result.data);
    }
  };

  function setLayoutTable() {
    function action(item) {
      return (
        <Button
          onClick={() => deleteItem(item)}
          variant={"outline-danger"}
          size="sm"
          style={{ padding: "5px 5px 5px 15px" }}
        >
          <Icon style={{ fontSize: "20px" }}>delete</Icon>
        </Button>
      );
    }
    function dataBody() {
      const openInNewTab = (url) => {
        const result = url.substring(0, 4);
        if (result !== "http") {
          url = "https://" + url;
        }
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      };

      let no = 0;
      return listTable.map((item) => {
        var selisih = DateMoment.days(item.expired);

        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {item.name}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {item.host}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {item.user}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {item.pass}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer", color: "#0463ef" }}
              onClick={() =>
                openInNewTab(
                  item.domain +
                    "/" +
                    item.path +
                    "/" +
                    item.screet +
                    "/admin/user/?sort=0"
                )
              }
            >
              {item.domain}
            </Tables.Td>
            <Tables.Td>{selisih}</Tables.Td>
            <Tables.Td>{action(item)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>NAME</Tables.Th>
            <Tables.Th>HOST</Tables.Th>
            <Tables.Th>USER</Tables.Th>
            <Tables.Th>PASSWORD</Tables.Th>
            <Tables.Th>DOMAIN</Tables.Th>
            <Tables.Th>HARI</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  function setLayoutModal() {
    let urutForm = 0;
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{modalTitle}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={refFrom}>
              <InputLabel
                title="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Host"
                value={host}
                onChange={(e) => {
                  setHost(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="User"
                value={user}
                onChange={(e) => {
                  setUser(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Domain"
                value={domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Path"
                value={path}
                onChange={(e) => {
                  setPath(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Screet"
                value={screet}
                onChange={(e) => {
                  setScreet(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title={"Expired"}
                value={expired}
                type="date"
                onChange={(e) => {
                  setExpired(e.target.value);
                  updateNotify(refFrom, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={submitHandler}>
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }

  function openModal(item) {
    if (item === "") {
      setModalTitle("Tambah Server");
      setIdServer("0");
      setName("");
      setHost("");
      setUser("");
      setPass("");
      setDomain("");
      setPath("");
      setScreet("");
      setExpired("");
    } else {
      setModalTitle("Edit Server");
      setIdServer(item.id);
      setName(item.name);
      setHost(item.host);
      setUser(item.user);
      setPass(item.pass);
      setDomain(item.domain);
      setPath(item.path);
      setScreet(item.screet);
      setExpired(item.expired);
    }
    setModal(true);
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete " + item.host, "Yes");
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      const result = await Service.master.server._delete(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === item.id) {
            listTable.splice(index, 1);
            setListTable(listTable);
            return new Swalert().success("Delete " + item.host + " Success");
          }
        }
      }
    }
  }

  function updateNotify(form, row, notify) {
    if (form.current !== null) {
      const label = form.current.childNodes[row].childNodes[0];
      const input = form.current.childNodes[row].childNodes[1];
      const danger = form.current.childNodes[row].childNodes[2];
      if (notify !== "") {
        danger.style.display = "block";
        danger.innerHTML = notify;
        input.focus();
      } else {
        danger.style.display = "none";
        danger.innerHTML = notify;
      }
    }
  }
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  function submitHandler() {
    async function createdServer() {
      setLoader(true);
      const payload = new FormData();
      payload.append("name", name);
      payload.append("host", host);
      payload.append("user", user);
      payload.append("pass", pass);
      payload.append("domain", domain);
      payload.append("path", path);
      payload.append("screet", screet);
      payload.append("expired", expired);

      const result = await Service.master.server._created(payload);
      setLoader(false);

      if (result.status) {
        listTable[listTable.length] = result.data;
        setListTable(listTable);
        setModal(false);
        return new Swalert().success("Add Server Success");
      } else {
        return new Swalert().warning("Silahkan Ulangi beberapa saat lagi");
      }
    }
    async function updateServer() {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", idServer);
      payload.append("name", name);
      payload.append("host", host);
      payload.append("user", user);
      payload.append("pass", pass);
      payload.append("domain", domain);
      payload.append("path", path);
      payload.append("screet", screet);
      payload.append("expired", expired);

      const result = await Service.master.server._update(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === idServer) {
            listTable[index] = result.data;
            setListTable(listTable);
            setModal(false);
            return new Swalert().success("Update Server Success");
          }
        }
      } else {
        return new Swalert().warning("Silahkan Ulangi beberapa saat lagi");
      }
    }

    const form = refFrom;
    if (name === "") {
      return updateNotify(form, 0, "belum di isi");
    } else if (host === "") {
      return updateNotify(form, 1, "belum di isi");
    } else if (user === "") {
      return updateNotify(form, 2, "belum di isi");
    } else if (pass === "") {
      return updateNotify(form, 3, "belum di isi");
    } else if (domain === "") {
      return updateNotify(form, 4, "belum di isi");
    } else if (path === "") {
      return updateNotify(form, 5, "belum di isi");
    } else if (screet === "") {
      return updateNotify(form, 6, "belum di isi");
    } else if (expired === "") {
      return updateNotify(form, 7, "belum di isi");
    } else if (expired === "") {
      return updateNotify(form, 8, "belum di isi");
    }
    if (idServer === "0") {
      createdServer();
    } else {
      updateServer();
    }
  }
  return (
    <Layout loader={false}>
      <Div style={{ margin: "10px" }}>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => openModal("")}
        >
          TAMBAH SERVER
        </Button>
      </Div>
      {setLayoutTable()}
      {setLayoutModal()}
    </Layout>
  );
}
export default User;
