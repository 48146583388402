//import { useNavigate } from "react-router-dom";

function NotFound() {
  //const navigate = useNavigate();
  //navigate("/");

  window.location.href = "/";
  return "NotFound";
}
export default NotFound;
