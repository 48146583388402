import GetEnvConfig from "./service/GetEnvConfig";

const storage = {
  themes: "color-theme",
  dataUser: "data-user",
  dataOrg: "data-org",
  accessMenu: "access-menu",
};

const configEnv = await GetEnvConfig();
const { orgName, baseUrl } = configEnv.data;

const config = {
  baseUrl: baseUrl,
  orgName: orgName,
  orgIcon: baseUrl + "files/default/logo_grey.svg",
  cryptoScreet: "cryptoScreet",
};

const authConfig = {
  apiKey: "AIzaSyDJtk48X4FIZ3JRt3A0tRYxwlIYk249lvk",
  authDomain: "miksimp.firebaseapp.com",
  projectId: "miksimp",
  storageBucket: "miksimp.appspot.com",
  messagingSenderId: "989683510590",
  appId: "1:989683510590:web:7f4f3f0075637aa600d06f",
  measurementId: "G-R9FT2SKFNQ",
};

//d:default, m:master, l:list, u:user
const pageMenu = {
  d_sigUp: "/sigUp",
  d_sigIn: "/sigIn",
  d_home: "/home",
  d_user: "/user",
  d_menus: "/menus",
  m_org: "/master/org",
  m_access: "/master/access",
  m_users: "/master/users",
  m_server: "/master/server",
  l_users: "/list/users",
  l_inject: "/list/inject",
  l_xray: "/list/xray",
  l_tarfik: "/list/trafik",
  l_whatsapp: "/list/whatsapp",
};
export { storage, config, pageMenu, authConfig };
