import { useEffect, useState } from "react";
import AccessMenu from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import Tables from "../../components/Tables";
import JWToken from "../../utils/JWToken";

function Whatsapp() {
  const { actCreated, actUpdate, actDelete } = AccessMenu();
  const [loader, setLoader] = useState(false);

  const [listTable, setListTable] = useState([]);

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.list.message._read(payload);
    setLoader(false);
    if (result.status) {
      setListTable(result.data);
    }
  };

  function setLayoutTable() {
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td>{item.whatsapp}</Tables.Td>
            <Tables.Td>{item.jadwal}</Tables.Td>
            <Tables.Td>{item.status}</Tables.Td>
            <Tables.Td>{item.message}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={(e) => (e.target.innerHTML = item.respon)}
            >
              click
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>WHATSAPP</Tables.Th>
            <Tables.Th>JADWAL</Tables.Th>
            <Tables.Th>STATUS</Tables.Th>
            <Tables.Th>MESSAGE</Tables.Th>
            <Tables.Th>RESPONSE</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  return <Layout loader={false}>{setLayoutTable()}</Layout>;
}
export default Whatsapp;
