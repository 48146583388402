import CriptoJS from "../../utils/CriptoJS";
import { storage } from "../../utils/StaticString";
import UseLocalStorage from "../../utils/UseLocalStorage";

export default function Access() {
  const pathname = window.location.pathname;
  const [accessMenu] = UseLocalStorage(storage.accessMenu, "");

  let actCreated = false;
  let actUpdate = false;
  let actDelete = false;

  const access = new CriptoJS().decript(accessMenu);
  if (access !== undefined) {
    let permission = [];
    for (let index = 0; index < access.length; index++) {
      const item = access[index];
      if (item.path === pathname) {
        permission = item.permission;
      }
    }

    for (let index = 0; index < permission.length; index++) {
      const access = permission[index];
      if (access === 0) {
        actCreated = true;
      } else if (access === 1) {
        actUpdate = true;
      } else if (access === 2) {
        actDelete = true;
      }
    }
  }
  return {
    actCreated: actCreated,
    actUpdate: actUpdate,
    actDelete: actDelete,
  };
}
