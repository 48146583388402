import { useEffect, useState } from "react";
import { config, storage } from "../../utils/StaticString";
import PageLayout from "./Layout";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import CriptoJS from "../../utils/CriptoJS";
import Swalert from "../../components/Swalert";
import { Service } from "../../utils/service";

const removeAllStorage = async () => {
  try {
    if (typeof window !== "undefined") {
      const cekStorageUser = window.localStorage.getItem(storage.dataUser);
      if (cekStorageUser !== null) {
        window.localStorage.removeItem(storage.dataUser);
      }

      const cekStorageAccess = window.localStorage.getItem(storage.accessMenu);
      if (cekStorageAccess !== null) {
        window.localStorage.removeItem(storage.accessMenu);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
function Refferal() {
  removeAllStorage();
  const { ref } = useParams();

  const [accessMenu, setAccessMenu] = UseLocalStorage(storage.accessMenu, "");
  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, "");
  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [theme, setTheme] = UseLocalStorage(storage.themes, "");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    submitHandler();
  }, []);

  const submitHandler = async () => {
    setLoader(true);
    const payload = new FormData();
    payload.append("refferal", ref);
    var result = await Service.auth._keyRand(payload);
    setLoader(false);
    return resultLogin(result);
  };

  const resultLogin = async (result) => {
    const data = result.data;
    if (result.req) {
      if (data.status === "succes_login") {
        if (data.dataOrg.orgName === "") {
          data.dataOrg.orgName = config.orgName;
        }
        if (data.dataOrg.orgIcon === "") {
          data.dataOrg.orgIcon = config.orgIcon;
        }

        setTheme(data.theme);
        setDataUser(data.dataUser);
        setDataOrg(data.dataOrg);

        setAccessMenu(new CriptoJS().encript(data.dataMenu));
        const swal = await new Swalert().success("Login Berhasil . . .");
        if (swal) {
          navigate("/");
        }
      } else {
        const swal = await new Swalert().warning(data.error);
        if (swal) {
          navigate("/");
        }
      }
    } else {
      new Swalert().error(data.message);
    }
  };

  return <PageLayout loader={loader}></PageLayout>;
}

export default Refferal;
