import Cookies from "js-cookie";
class JWToken {
  constructor() {
    this.cookie = "JWToken";
  }
  _getStatusJWToken() {
    if (Cookies.get(this.cookie)) {
      return true;
    }
    return false;
  }
  _getValueJWToken() {
    return Cookies.get(this.cookie);
  }
  _setJWToken(jtw, expired) {
    var exp = new Date(expired);
    Cookies.set(this.cookie, jtw, {
      expires: exp,
    });
    Cookies.set("expired", expired, {
      expires: exp,
    });
  }
  _removeCookie = () => {
    Cookies.remove(this.cookie);
    Cookies.remove("expired");
    return true;
  };
}

export default JWToken;
