import { useEffect, useState } from "react";
import Access from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import Swalert from "../../components/Swalert";
import { ButtonGroup, Image, Modal, ToggleButton } from "react-bootstrap";
import Icon from "../../components/Icon";
import { config } from "../../utils/StaticString";
import { profile } from "../../assets";
import Tables from "../../components/Tables";
import SelectLabel from "../../components/SelectLabel";
import Div from "../../components/Div";
import JWToken from "../../utils/JWToken";

function Users() {
  const { actCreated, actUpdate, actDelete } = Access();
  const [loader, setLoader] = useState(false);

  const [listTable, setListTable] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [idUser, setIdUser] = useState("");
  const [optionAccess, setOptionAccess] = useState("");
  const [newAccess, setNewAccess] = useState("");

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }
  const optionsGender = [
    { value: "male", label: "Laki Laki" },
    { value: "female", label: "Perempuan" },
  ];

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.master.users._read(payload);
    setLoader(false);
    if (result.status) {
      setListTable(result.data.list);

      const listAccessOptions = [];
      const listAccess = result.data.access;
      for (let index = 0; index < listAccess.length; index++) {
        const item = listAccess[index];
        const newItem = { value: item.id, label: item.accessName };
        listAccessOptions.push(newItem);
      }

      setOptionAccess(listAccessOptions);
    }
  };

  async function openModal(item) {
    if (parseInt(item.id) === 1) {
      return new Swalert().warning("Akses dikunci untuk user " + item.name);
    }
    setModalTitle("Update Access " + item.name);
    setIdUser(item.id);

    optionAccess.map((itemA) => {
      if (parseInt(item.accessId) === parseInt(itemA.value)) {
        setNewAccess(itemA);
      }
    });

    setModal(true);
  }

  async function onchangeSelect(select) {
    setLoader(true);
    const payload = new FormData();
    payload.append("id", idUser);
    payload.append("accessId", select.value);
    const result = await Service.master.users._update(payload);
    setLoader(false);
    if (result.status) {
      setModal(false);

      let newData = "";
      listTable.map((item, index) => {
        if (item.id === idUser) {
          newData = {
            id: idUser,
            accessId: select.value,
            name: item.name,
            gender: item.gender,
            temLahir: item.temLahir,
            tglLahir: item.tglLahir,
            whatsapp: item.whatsapp,
            images: item.images,
          };
          listTable[index] = newData;
        }
      });
      setListTable(listTable);
      new Swalert().success("Update Success");
    } else {
      return new Swalert().warning(result.data);
    }
  }

  async function resetItem(item) {
    const swal = await new Swalert().confirm("Reset Login " + item.name, "Yes");
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      const result = await Service.master.users._reset(payload);
      setLoader(false);
      if (result.status) {
        new Swalert().success("Reset Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
  }
  async function lockedItem(item) {
    let valueLocked = parseInt(item.locked);
    item.locked = valueLocked;
    if (valueLocked === 1) {
      item.locked = 0;
    } else {
      item.locked = 1;
    }

    let lockedNotify = "Unlock";
    if (item.locked === 1) {
      lockedNotify = "Locked";
    }

    const swal = await new Swalert().confirm(
      lockedNotify + " Access Login " + item.name,
      "Yes"
    );

    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      payload.append("locked", item.locked);

      const result = await Service.master.users._locked(payload);
      setLoader(false);
      if (result.status) {
        listTable.map((itemL, index) => {
          if (itemL.id === item.id) {
            const newData = {
              id: item.id,
              locked: item.locked,
              accessId: item.accessId,
              name: item.name,
              gender: item.gender,
              temLahir: item.temLahir,
              tglLahir: item.tglLahir,
              whatsapp: item.whatsapp,
              images: item.images,
            };
            listTable[index] = newData;
          }
        });
        setListTable(listTable);
        new Swalert().success(lockedNotify + " Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
  }

  function setLayoutTable() {
    function action(item) {
      const disabled = parseInt(item.id) === 1 ? true : false;
      return (
        <ButtonGroup>
          <ToggleButton
            disabled={disabled}
            key={randNumb()}
            variant={disabled ? "outline-primary" : "primary"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => resetItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
              lock_reset
            </Icon>
          </ToggleButton>
          <ToggleButton
            disabled={disabled}
            key={randNumb()}
            variant={
              disabled
                ? "outline-success"
                : parseInt(item.locked) === 0
                ? "success"
                : "danger"
            }
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => lockedItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
              {parseInt(item.locked) === 0 ? "lock_open" : "lock"}
            </Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function image(item) {
      return (
        <Image
          alt="img"
          src={item.images === "" ? profile.src : config.baseUrl + item.images}
          style={{ width: "30px", cursor: "pointer" }}
        />
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {image(item)}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {capitalizeFirstLetter(item.name)}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {optionsGender.map((itemG) => {
                if (item.gender === itemG.value) {
                  return itemG.label;
                }
              })}
            </Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {item.whatsapp}
            </Tables.Td>
            <Tables.Td>{action(item)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>PROFILE</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>JENIS KELAMIN</Tables.Th>
            <Tables.Th>WHATSAPP</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setLayoutModal() {
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <SelectLabel
              title={modalTitle}
              isSearchable={false}
              defaultValue={newAccess}
              options={optionAccess}
              onChange={(select) => onchangeSelect(select)}
            />
          </Modal.Body>
        </Div>
      </Modal>
    );
  }
  return (
    <Layout loader={false}>
      {setLayoutTable()}
      {setLayoutModal()}
    </Layout>
  );
}
export default Users;
