import Access from "./../PageLayout/Access";
import Layout from "./../PageLayout/Layout";

import { createRef, useState } from "react";
import { config, storage } from "../../utils/StaticString";
import UseLocalStorage from "../../utils/UseLocalStorage";
import Swalert from "../../components/Swalert";
import { Service } from "../../utils/service";
import { Button, Image, Modal, Tab, Tabs } from "react-bootstrap";
import Div from "../../components/Div";
import Span from "../../components/Span";
import EasyCrop from "../../components/cropped/EasyCrop";
import InputLabel from "../../components/InputLabel";
import PhoneLabel from "../../components/PhoneLabel";

function Organization() {
  const { actCreated, actUpdate, actDelete } = Access();

  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [loader, setLoader] = useState(false);

  const [modal, setModal] = useState(false);

  const [organization, setOrganization] = useState(dataOrg.orgName);
  const [imageCrop, setImageCrop] = useState(dataOrg.orgIcon);

  const [modalOther, setModalOther] = useState(false);
  const [whatsapp, setWhatsapp] = useState("");

  const refFile = createRef();
  const refCrop = createRef();
  const refFrom = createRef();

  const clickSelect = (e) => {
    refFile.current.click();
  };
  const selectFile = (e) => {
    if (e.target.files[0]) {
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };

  const saveImageCrop = async ({ status, response }) => {
    if (!status) {
      return new Swalert().warning("Please, select new image");
    }
    let payload = new FormData();
    payload.append("image", response);

    setLoader(true);
    const result = await Service.master.org._logo(payload);
    setLoader(false);
    if (result.status) {
      setModal(false);
      if (result.data.orgName === "") {
        result.data.orgName = config.orgName;
      }
      if (result.data.orgIcon === "") {
        result.data.orgIcon = config.orgIcon;
      }
      setDataOrg(result.data);
      new Swalert().success("Update Logo Organization Success");
    }
  };
  const saveDetail = async () => {
    if (organization === "") {
      return new Swalert().warning("Nama tidak boleh kosong.");
    }
    const payload = new FormData();
    payload.append("orgName", organization);

    setLoader(true);
    const result = await Service.master.org._update(payload);
    setLoader(false);
    if (result.status) {
      if (result.data.orgName === "") {
        result.data.orgName = config.orgName;
      }
      if (result.data.orgIcon === "") {
        result.data.orgIcon = config.orgIcon;
      }
      setDataOrg(result.data);
      new Swalert().success("Update Name Organization Success");
    }
  };

  const saveOther = async () => {
    const payload = new FormData();
    payload.append("noWaNotif", whatsapp);

    setLoader(true);
    const result = await Service.master.org._update(payload);
    setLoader(false);
    if (result.status) {
      setModalOther(false);
      new Swalert().success("Update Whatsapp Notifikasi Success");
    }
  };
  function setLayoutModal() {
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Update Icon</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <Button
              style={{ marginBottom: "10px" }}
              onClick={() => clickSelect()}
            >
              <Span>Select File</Span>
            </Button>

            <EasyCrop
              image={imageCrop}
              rHeight={5}
              rWidth={5}
              refCrop={refCrop}
              clickSave={saveImageCrop}
            />
            <input
              type="file"
              accept="image/png, image/jpeg, image/svg+xml"
              style={{ height: "0", overflow: "hidden" }}
              ref={refFile}
              onChange={selectFile}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => refCrop.current.click()}
            >
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }
  function setLayoutModalOther() {
    return (
      <Modal
        show={modalOther}
        onHide={() => setModalOther(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Update Data</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PhoneLabel
              title="Whatsapp Notifikasi"
              value={whatsapp}
              onChange={(e) => {
                setWhatsapp(e);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={saveOther}>
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }
  async function clickTab(e) {
    const title = e.target.childNodes[0].data;
    if (title === "WHATSAPP") {
      setModalOther(true);
      setLoader(true);
      const payload = new FormData();
      const result = await Service.master.org._read(payload);
      setLoader(false);
      if (result.status) {
        setWhatsapp(result.data.noWaNotif);
      }
    }
  }
  return (
    <Layout loader={loader}>
      <Div style={{ margin: "5px" }}>
        <Tabs
          defaultActiveKey="1"
          transition={true}
          onClick={(e) => clickTab(e)}
        >
          <Tab eventKey="1" title="LOGO">
            <Image
              alt="img"
              src={dataOrg.orgIcon}
              onClick={() => setModal(true)}
              style={{
                width: "100px",
                borderRadius: "5px",
                cursor: "pointer",
                margin: "20px",
              }}
            />
          </Tab>
          <Tab eventKey="2" title="TITLE">
            <InputLabel
              title={"Nama Orginization"}
              type={"text"}
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
            <Button variant="outline-primary" onClick={saveDetail}>
              Simpan
            </Button>
          </Tab>
        </Tabs>
      </Div>
      {setLayoutModal()}
      {setLayoutModalOther()}
    </Layout>
  );
}
export default Organization;
