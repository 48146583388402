import { Card, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { storage } from "./../../utils/StaticString";
import Theme from "./../../utils/Theme";
import UseLocalStorage from "./../../utils/UseLocalStorage";
import { Service } from "./../../utils/service";

import Div from "./../../components/Div";
import Icon from "./../../components/Icon";

export default function Header({ props }) {
  const { styleIconDark, actSidebar } = props;
  const { headerName, HeaderUser } = props;

  const [theme, setTheme] = UseLocalStorage(storage.themes, "light_mode");

  const [iconTheme, setIconTheme] = useState("");
  const [cssTheme, setCssTheme] = useState("");

  useEffect(() => {
    updateTheme();
  }, [theme]);

  const updateTheme = async () => {
    if (theme === "dark_mode") {
      setCssTheme(Theme.Dark_Mode());
      setIconTheme(<Icon>light_mode</Icon>);
    } else {
      setCssTheme(Theme.Light_Mode());
      setIconTheme(<Icon addClass={"-two-tone"}>dark_mode</Icon>);
    }
  };
  const updateThemeSql = async (theme) => {
    const payload = new FormData();
    payload.append("themes", theme);
    await Service.user._update(payload);
  };
  return (
    <>
      {cssTheme}
      <Div className="users">
        <Card>
          <HeaderUser />
        </Card>
      </Div>
      <Div className="title">
        <Card>
          <Nav as="ul" className="navbar-nav">
            <Nav.Item as="li" className="nav-item" style={styleIconDark}>
              <Icon addClass=" icon" onClick={actSidebar}>
                last_page
              </Icon>
              <Div className="header">
                <b style={{ cursor: "pointer" }} onClick={actSidebar}>
                  {headerName}
                </b>
              </Div>
            </Nav.Item>
          </Nav>
          <Nav as="ul" className="navbar-nav ms-auto">
            <Nav.Item as="li" className="nav-item">
              <Div
                className="icon-dark"
                style={styleIconDark}
                onClick={() => {
                  if (typeof setTheme === "function") {
                    if (theme === "light_mode") {
                      setTheme("dark_mode");
                      updateThemeSql("dark_mode");
                    } else {
                      setTheme("light_mode");
                      updateThemeSql("light_mode");
                    }
                  }
                }}
              >
                {iconTheme}
              </Div>
            </Nav.Item>
          </Nav>
        </Card>
      </Div>
    </>
  );
}
