import { createRef, useEffect, useState } from "react";
import AccessMenu from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import {
  Button,
  ButtonGroup,
  Form,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import Icon from "../../components/Icon";
import { RWebShare } from "react-web-share";
import Tables from "../../components/Tables";
import Div from "../../components/Div";
import SelectLabel from "../../components/SelectLabel";
import InputLabel from "../../components/InputLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Swalert from "../../components/Swalert";
import { v4 as uuidv4 } from "uuid";
import DateMoment from "../../utils/DateMoment";
import JWToken from "../../utils/JWToken";

function Xray() {
  const { actCreated, actUpdate, actDelete } = AccessMenu();

  const [loader, setLoader] = useState(false);
  const [listTable, setListTable] = useState([]);

  const [optionsServer, setOptionsServer] = useState([]);
  const [optionsUser, setOptionsUser] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const refFrom = createRef();
  const [idRow, setIdRow] = useState("");
  const [server, setServer] = useState("");
  const [user, setUser] = useState("");
  const [nama, setNama] = useState("");
  const [uuid, setUuid] = useState("");
  const [limitGB, setLimitGB] = useState("");
  const [currentGB, setCurrentGB] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [expired, setExpired] = useState("");
  const [lastOnline, setLastOnline] = useState(null);

  useEffect(() => {
    console.log(expired);
  }, [expired]);
  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.list.xray._read(payload);
    setLoader(false);

    if (result.status) {
      const listXray = result.data.xray;
      setListTable(listXray);

      const optionSer = [];
      const listSer = result.data.server;
      listSer.map((item) => {
        const newItem = {
          value: item.id,
          label: item.name,
          domain: item.domain,
        };
        optionSer.push(newItem);
      });
      setOptionsServer(optionSer);

      const optionUsr = [];
      const listUsers = result.data.users;
      listUsers.map((item) => {
        const newItem = {
          value: item.id,
          label: item.name,
          whatsapp: item.whatsapp,
        };
        optionUsr.push(newItem);
      });
      setOptionsUser(optionUsr);

      const resultSer = await Service.list.xray._statusServer(payload);
      if (resultSer.status) {
        const listStatusServer = resultSer.data.statusServer;

        const newListXray = [];
        listXray.map((item) => {
          item.last_online = "";
          item.currentGB = "0";
          for (let index = 0; index < listStatusServer.length; index++) {
            const element = listStatusServer[index];
            if (item.uuid === element.uuid) {
              item.last_online = element.last_online;
              item.currentGB = element.current_usage_GB;
              item.limitGB = element.usage_limit_GB;
            }
          }
          newListXray.push(item);
        });

        console.log(newListXray);
        setListTable(newListXray);
      }
    }
  };

  function setLayoutTable() {
    function action(item, selisih) {
      return (
        <ButtonGroup>
          <ToggleButton
            key={randNumb()}
            variant={"outline-danger"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => deleteItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>delete</Icon>
          </ToggleButton>

          <ToggleButton
            key={randNumb()}
            variant={selisih < 20 ? "outline" : "outline-success"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => (selisih < 20 ? "" : sendMessage(item))}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>chat</Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        //item.expired = item.expired.substring(0, 10);

        var selisih = DateMoment.days(item.expired);
        var last = "";
        if (item.last_online !== "1-01-01 00:00:00") {
          last = DateMoment.dateTo(item.last_online);
        }

        var server = "";
        for (let index = 0; index < optionsServer.length; index++) {
          const element = optionsServer[index];
          if (element.value === item.idServer) {
            server = element.domain;
          }
        }

        let style = { cursor: "pointer" };
        if (selisih < 0) {
          style = { cursor: "pointer", color: "#dc3545" };
        } else if (selisih < 5) {
          style = { cursor: "pointer", color: "#FF9500" };
        }

        let styles = {};
        if (last === "" || selisih < 0) {
          styles = { color: "#dc3545" };
        }
        if (last === "Invalid date") {
          last = "";
        }

        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td style={style}>{server}</Tables.Td>
            <Tables.Td style={style} onClick={() => openModal(item)}>
              {item.nama}
            </Tables.Td>
            <Tables.Td style={style} onClick={() => openModal(item)}>
              {item.uuid}
            </Tables.Td>
            <Tables.Td style={style}>
              {round(item.currentGB, 1) + "/" + item.limitGB}
            </Tables.Td>
            <Tables.Td style={style}>{selisih}</Tables.Td>
            <Tables.Td style={styles}>{last}</Tables.Td>
            <Tables.Td>{action(item, selisih)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>SERVER</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>UUID</Tables.Th>
            <Tables.Th>GB</Tables.Th>
            <Tables.Th>HARI</Tables.Th>
            <Tables.Th>ONLINE</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  function setLayoutModal() {
    function setNomorWA(e) {
      if (parseInt(idRow) === 0) {
        setWhatsapp(e.whatsapp);
      }
    }
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{modalTitle}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={refFrom}>
              <SelectLabel
                title={"Server"}
                isSearchable={false}
                defaultValue={server}
                onChange={(e) => {
                  setServer(e);
                }}
                options={optionsServer}
                getOptionLabel={(item) => `${item.label} : ${item.domain}`}
              />
              <SelectLabel
                title={"Pemilik Xray"}
                isSearchable={true}
                defaultValue={user}
                onChange={(e) => {
                  setUser(e);
                  setNomorWA(e);
                }}
                options={optionsUser}
              />
              <InputLabel
                title={"Nama"}
                value={nama}
                onChange={(e) => {
                  setNama(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="UUID"
                value={uuid}
                onChange={(e) => {
                  setUuid(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Limit GB"
                value={limitGB}
                type={"number"}
                onChange={(e) => {
                  setLimitGB(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Current GB"
                value={currentGB}
                type={"text"}
                onChange={(e) => {
                  setCurrentGB(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
              <PhoneLabel
                title="Nomor Whatsapp"
                value={whatsapp}
                onChange={(e) => {
                  setWhatsapp(e);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title={"Expired"}
                value={expired}
                type="datetime-local"
                onChange={(e) => {
                  setExpired(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={submitHandler}>
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }

  function openModal(item) {
    if (item === "") {
      var dt = new Date();
      var getFullYear = dt.getFullYear();
      var getMonth = dt.getMonth() + 1;
      var getDate = dt.getDate();
      if (getMonth < 10) {
        getMonth = "0" + (dt.getMonth() + 1);
      }
      if (getDate < 10) {
        getDate = "0" + dt.getDate();
      }
      var date = getFullYear + "-" + getMonth + "-" + getDate + " 23:59";
      let myuuid = uuidv4();

      setModalTitle("Tambah Xray");
      setIdRow("0");
      setServer("");
      setUser("");
      setNama("");
      setUuid(myuuid);
      setLimitGB("0");
      setCurrentGB("0");
      setWhatsapp("");
      setExpired(date);
      setLastOnline(null);
    } else {
      setModalTitle("Edit Xray");
      setIdRow(item.id);
      setNama(item.nama);
      setUuid(item.uuid);
      setLimitGB(item.limitGB);
      setCurrentGB(item.currentGB);
      setWhatsapp(item.whatsapp);
      setExpired(item.expired.replace(" ", "T"));
      setLastOnline(item.last_online);
      optionsServer.map((itemS) => {
        if (item.idServer === itemS.value) {
          setServer(itemS);
        }
      });
      optionsUser.map((itemU) => {
        if (item.idUser === itemU.value) {
          setUser(itemU);
        }
      });
    }
    setModal(true);
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete " + item.nama, "Yes");
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      payload.append("idServer", item.idServer);
      payload.append("uuid", item.uuid);
      payload.append("lastOnline", lastOnline);

      const result = await Service.list.xray._delete(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === item.id) {
            listTable.splice(index, 1);
            setListTable(listTable);
            return new Swalert().success("Delete " + item.nama + " Success");
          }
        }
      }
    }
  }
  async function sendMessage(item) {
    const swal = await new Swalert().confirm(
      "Kirim Perpanjangan " + item.nama,
      "Yes"
    );
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);

      const result = await Service.list.xray._sendNotify(payload);
      setLoader(false);
      if (result.status) {
        return new Swalert().success(
          "Scheduler Send " + item.nama + " Success"
        );
      }
    }
  }

  function updateNotify(form, row, notify) {
    if (form.current !== null) {
      const label = form.current.childNodes[row].childNodes[0];
      const input = form.current.childNodes[row].childNodes[1];
      const danger = form.current.childNodes[row].childNodes[2];
      if (notify !== "") {
        danger.style.display = "block";
        danger.innerHTML = notify;
        input.focus();
      } else {
        danger.style.display = "none";
        danger.innerHTML = notify;
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  function submitHandler() {
    async function createdServer() {
      setLoader(true);
      const payload = new FormData();
      payload.append("idServer", server.value);
      payload.append("idUser", user.value);
      payload.append("nama", nama);
      payload.append("uuid", uuid);
      payload.append("limitGB", limitGB);
      payload.append("currentGB", currentGB);
      payload.append("whatsapp", whatsapp);
      payload.append("expired", expired);
      payload.append("lastOnline", lastOnline);

      const result = await Service.list.xray._created(payload);
      setLoader(false);

      if (result.status) {
        listTable[listTable.length] = result.data;
        setListTable(listTable);
        setModal(false);
        return new Swalert().success("Add Server Success");
      } else {
        return new Swalert().warning("Silahkan Ulangi lagi");
      }
    }
    async function updateServer() {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", idRow);
      payload.append("idServer", server.value);
      payload.append("idUser", user.value);
      payload.append("nama", nama);
      payload.append("uuid", uuid);
      payload.append("limitGB", limitGB);
      payload.append("currentGB", currentGB);
      payload.append("whatsapp", whatsapp);
      payload.append("expired", expired);
      payload.append("lastOnline", lastOnline);

      const result = await Service.list.xray._update(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === idRow) {
            listTable[index] = result.data;
            setListTable(listTable);
            setModal(false);
            return new Swalert().success("Update Server Success");
          }
        }
      } else {
        return new Swalert().warning("Silahkan Ulangi lagi");
      }
    }

    const form = refFrom;
    for (let index = 0; index <= 6; index++) {
      updateNotify(form, index, "");
    }
    if (server === "") {
      return updateNotify(form, 0, "belum di pilih");
    } else if (user === "") {
      return updateNotify(form, 1, "belum di pilih");
    } else if (nama === "") {
      return updateNotify(form, 2, "belum di isi");
    } else if (uuid === "") {
      return updateNotify(form, 3, "belum di isi");
    } else if (whatsapp === "") {
      return updateNotify(form, 4, "belum di isi");
    } else if (expired === "") {
      return updateNotify(form, 5, "belum di isi");
    } else if (expired === "") {
      return updateNotify(form, 6, "belum di isi");
    }
    if (idRow === "0") {
      createdServer();
    } else {
      updateServer();
    }
  }
  return (
    <Layout loader={false}>
      <Div style={{ margin: "10px" }}>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => openModal("")}
        >
          TAMBAH XRAY
        </Button>
      </Div>
      {setLayoutTable()}
      {setLayoutModal()}
    </Layout>
  );
}
export default Xray;
