import { storage } from "./../utils/StaticString";
import UseLocalStorage from "./../utils/UseLocalStorage";

import { Form } from "react-bootstrap";
import Select from "react-select";

const SelectLabel = ({ title, options, ...rest }) => {
  const [theme] = UseLocalStorage(storage.themes, "light_mode");

  if (options === undefined) options = [];

  var styleCustom = "";
  if (theme === "dark_mode") {
    styleCustom = {
      control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: "#2d2d3f",
        backgroundColor: "#ffffff00",
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: "#2d2d3f",
        backgroundColor: state.isFocused
          ? "#2d2d3fb8"
          : state.isSelected
          ? "#0c0c0c"
          : "#181821",
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#181821",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#c2c4d1",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#4c4c4d",
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        cursor: "text",
        color: "#c2c4d1",
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#c2c4d1",
        borderColor: "#2d2d3f",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: "#909aa7",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "#fff",
      }),
    };
  } else {
    styleCustom = {
      control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#5e6063",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#4c4c4d",
      }),

      multiValueLabel: (provided, state) => ({
        ...provided,
        cursor: "text",
        color: "#5e6063",
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#5e6063",
        borderColor: "#2d2d3f",
      }),
    };
  }
  return (
    <Form.Group className="mb-4">
      {title !== undefined ? <Form.Label>{title}</Form.Label> : ""}
      <Select styles={styleCustom} options={options} {...rest} />
      <Form.Text
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default SelectLabel;
