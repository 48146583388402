import { Form } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import Div from "./Div";

import transition from "react-element-popper/animations/transition";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import UseLocalStorage from "../utils/UseLocalStorage";
import { storage } from "../utils/StaticString";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useRef } from "react";
import DateMoment from "../utils/DateMoment";

const DatePickerLabel = ({
  title,
  range,
  onlyMonthPicker,
  onChange,
  ...rest
}) => {
  const refDate = useRef();
  const refNotif = useRef();
  const [theme] = UseLocalStorage(storage.themes, "");
  if (range === undefined) {
    range = false;
  }
  if (onlyMonthPicker === undefined) {
    onlyMonthPicker = false;
  }
  let formatDate = "DD-MMM-YYYY";
  if (onlyMonthPicker) {
    formatDate = "MMM-YYYY";
  }
  const weekDays = ["Mng", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];

  function dateFormat(date) {
    var dt = new Date(date);
    var getFullYear = dt.getFullYear();
    var getMonth = dt.getMonth() + 1;
    var getDate = dt.getDate();

    if (parseInt(getMonth) < 10) {
      getMonth = "0" + getMonth;
    }
    if (parseInt(getDate) < 10) {
      getDate = "0" + getDate;
    }

    if (onlyMonthPicker) {
      return getFullYear + "-" + getMonth + "-01";
    }
    return getFullYear + "-" + getMonth + "-" + getDate;
  }

  function onChanged(date) {
    if (range) {
      const dateOne = date[0];
      const dateTwo = date[1];
      if (dateTwo !== undefined) {
        refDate.current.closeCalendar();

        if (typeof onChange === "function") {
          if (onlyMonthPicker) {
            var selisih = DateMoment.month(dateTwo, dateOne);
            if (selisih >= 12) {
              refNotif.current.style.display = "block";
              refNotif.current.innerHTML = "maksimal 12 bulan";
            } else {
              refNotif.current.style.display = "none";
              onChange([dateFormat(dateOne), dateFormat(dateTwo)]);
            }
          } else {
            var selisih = DateMoment.days(dateTwo, dateOne);
            if (selisih >= 30) {
              refNotif.current.style.display = "block";
              refNotif.current.innerHTML = "maksimal 30 hari";
            } else {
              refNotif.current.style.display = "none";
              onChange([dateFormat(dateOne), dateFormat(dateTwo)]);
            }
          }
        }
      }
    } else {
      if (typeof onChange === "function") {
        onChange(dateFormat(date));
      }
    }
  }
  return (
    <Form.Group className="mb-4">
      {title !== undefined ? (
        <Div>
          <Form.Label>{title}</Form.Label>
        </Div>
      ) : (
        ""
      )}
      <DatePicker
        ref={refDate}
        editable={false}
        onChange={onChanged}
        className={theme === "dark_mode" ? "bg-dark" : ""}
        animations={[transition()]}
        style={{ height: "34px", width: "100%" }}
        containerStyle={{
          width: "100%",
        }}
        range={range}
        onlyMonthPicker={onlyMonthPicker}
        format={formatDate}
        rangeHover
        arrow={false}
        weekDays={weekDays}
        plugins={[<DatePanel />]}
        dateSeparator="  ke  "
      />
      <Form.Text
        ref={refNotif}
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default DatePickerLabel;
