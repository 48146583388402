import { useState, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";

import { storage, config, pageMenu } from "./../../utils/StaticString";
import UseLocalStorage from "./../../utils/UseLocalStorage";
import CriptoJS from "./../../utils/CriptoJS";

import Div from "./../../components/Div";
import Span from "./../../components/Span";
import { profile } from "./../../assets";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import NotFound from "../ForMe/NotFound";

export default function Layout({ children, loader }) {
  const [getLoader, setLoader] = useState(loader);

  const [headerName, setHeaderName] = useState("");
  const [accessMenu] = UseLocalStorage(storage.accessMenu, "");
  const [dataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [dataUser] = UseLocalStorage(storage.dataUser, "");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [styleApp, setStyleApp] = useState("app");
  const [styleIconDark, setStyleIconDark] = useState({ display: "block" });

  const navigate = useNavigate();

  useEffect(() => {
    setHeader();
  }, []);

  const accessPage = () => {
    if (accessMenu !== "") {
      const pathname = window.location.pathname;
      const access = new CriptoJS().decript(accessMenu);
      if (access) {
        for (let index = 0; index < access.length; index++) {
          const item = access[index];
          if (item.path === pathname) {
            return true;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setHeader = () => {
    const pathname = window.location.pathname;
    const access = new CriptoJS().decript(accessMenu);
    if (access !== undefined) {
      for (let index = 0; index < access.length; index++) {
        const item = access[index];
        if (item.path === pathname) {
          return setHeaderName(item.label.toUpperCase());
        }
      }
    }
  };

  const changeStyle = () => {
    const { innerWidth, innerHeight } = window;
    if (innerWidth <= 1199) {
      setSidebarOpen(!sidebarOpen);
      if (sidebarOpen) {
        setStyleApp("app");
        setTimeout(() => {
          setStyleIconDark({ display: "block" });
        }, 200);
      } else {
        setStyleIconDark({ display: "none" });
        setStyleApp("app sidebar-hidden");
      }
    }
  };

  const openRoute = (slug) => {
    if (sidebarOpen) {
      setLoader(true);
      changeStyle();
    }
    navigate(slug);
  };

  const HeaderUser = () => {
    return (
      <>
        <Image
          className="logo-org"
          src={dataOrg.orgIcon}
          style={{ cursor: "pointer" }}
          onClick={() => {
            openRoute(pageMenu.d_home);
          }}
        />
        <Div
          className="user"
          style={{ cursor: "pointer" }}
          onClick={() => {
            openRoute(pageMenu.d_user);
          }}
        >
          <Image src={dataUser.profile === "" ? profile : dataUser.profile} />
          <Span className="activity-indicator"></Span>
          <Div className="user-info-text">
            <Span className="user-state-info">{dataUser.user}</Span>
            <br />
            <Span>{dataUser.name}</Span>
          </Div>
        </Div>
      </>
    );
  };

  function propSideBar() {
    return {
      actSidebar: changeStyle,
      HeaderUser: HeaderUser,
      setHeader: setHeader,
    };
  }
  function propHeader() {
    return {
      styleIconDark: styleIconDark,
      actSidebar: changeStyle,
      headerName: headerName,
      HeaderUser: HeaderUser,
    };
  }

  if (accessPage()) {
    return (
      <>
        <Helmet>
          <title>
            {headerName + " | "}
            {dataOrg === "" ? config.orgName : dataOrg.orgName}
          </title>
          <link
            rel="icon"
            href={dataOrg === "" ? config.orgIcon : dataOrg.orgIcon}
          />
        </Helmet>
        <LoadingOverlay
          active={getLoader}
          spinner
          styles={{
            wrapper: {
              overflow: "none",
            },
          }}
        />
        <Div className={styleApp}>
          <Div className="hide-app-sidebar-mobile" onClick={changeStyle}></Div>
          <Div className="app-sidebar">
            <Sidebar props={propSideBar()} />
          </Div>
          <Div className="app-container">
            <Div className="layout-header">
              <Header props={propHeader()} />
            </Div>
            <Div className="app-content">
              <Card>{children}</Card>
            </Div>
          </Div>
        </Div>
      </>
    );
  } else {
    return <NotFound />;
  }
}
