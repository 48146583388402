import { Table as Bostrap } from "react-bootstrap";

function randNumb() {
  return Math.floor(Math.random() * 1000000);
}
const TableBostrap = ({ children, ...props }) => {
  return (
    <Bostrap responsive striped bordered hover {...props}>
      {children}
    </Bostrap>
  );
};
const Head = ({ children, ...props }) => {
  return (
    <thead key={randNumb()} {...props}>
      {children}
    </thead>
  );
};
const Body = ({ children, ...props }) => {
  return (
    <tbody key={randNumb()} {...props}>
      {children}
    </tbody>
  );
};
const Footer = ({ children, ...props }) => {
  return (
    <tfoot key={randNumb()} {...props}>
      {children}
    </tfoot>
  );
};
const Tr = ({ children, ...props }) => {
  return (
    <tr key={randNumb()} {...props}>
      {children}
    </tr>
  );
};
const Th = ({ children, ...props }) => {
  return (
    <th key={randNumb()} {...props}>
      {children}
    </th>
  );
};
const Td = ({ children, ...props }) => {
  return (
    <td key={randNumb()} {...props}>
      {children}
    </td>
  );
};

const Tables = {
  Table: TableBostrap,
  Head: Head,
  Body: Body,
  Footer: Footer,
  Tr: Tr,
  Th: Th,
  Td: Td,
};

export default Tables;
