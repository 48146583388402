import { createRef, useCallback, useEffect, useState } from "react";
import UseLocalStorage from "../../utils/UseLocalStorage";
import Access from "./../PageLayout/Access";
import Layout from "./../PageLayout/Layout";
import { storage } from "../../utils/StaticString";
import { IdnKab, profile } from "../../assets";
import { Service } from "../../utils/service";
import Swalert from "../../components/Swalert";
import getCroppedImg from "../../components/cropped/Crop";
import InputLabel from "../../components/InputLabel";
import { Button, Form, Image, Modal } from "react-bootstrap";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import Cropper from "react-easy-crop";
import Div from "../../components/Div";
import Icon from "../../components/Icon";
import AuthGoogle from "../../utils/AuthGoogle";

function User() {
  const { actCreated, actUpdate, actDelete } = Access();

  const actUserLogin = "userLogin";
  const actPassword = "Password";
  const actDetail = "Detail";
  const actProfile = "Profile";
  const actLoginPassword = "LoginPassword";

  const [userAuth, logIn, logOut] = AuthGoogle();
  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, "");
  const [iconEmail, setIconEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const [modal, setModal] = useState(false);
  const [action, setAction] = useState("");

  const [hideUserLogin, setHideUserLogin] = useState("none");
  const [hideUserPassword, setHideUserPassword] = useState("none");
  const [hideUserDetail, setHideUserDetail] = useState("none");
  const [hideUserProfile, setHideUserProfile] = useState("none");
  const [HideNewLoginPassword, setHideNewLoginPassword] = useState("none");

  const [modalTitle, setModalTitle] = useState("");
  const [closeModal, setCloseModal] = useState(true);

  const [userLogin, setUserLogin] = useState("");
  const [userLoginBaru, setUserLoginBaru] = useState("");
  const [password, setPassword] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [ulangiPasswordBaru, setUlangiPasswordBaru] = useState("");

  const refFile = createRef();
  const [imageCrop, setImageCrop] = useState(dataUser.profile);

  const [namaLengkap, setNamaLengkap] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [noWhatsapp, setNoWhatsapp] = useState("");

  const refUserLogin = createRef();
  const refUserPassword = createRef();
  const refUserDetail = createRef();
  const refNewLoginPassword = createRef();

  const optionsGender = [
    { value: "male", label: "Laki Laki" },
    { value: "female", label: "Perempuan" },
  ];

  const optionsKab = [];
  new IdnKab().getAllData().map((item) => {
    const newItem = { value: item.indID, label: item.indName };
    optionsKab.push(newItem);
  });

  useEffect(() => {
    if (dataUser.user === "") {
      openModal(actLoginPassword);
    } else if (dataUser.reset) {
      openModal(actPassword);
    }
  }, []);

  useEffect(() => {
    if (dataUser.email === "") {
      setIconEmail("mail_lock");
    } else {
      setIconEmail("mark_email_read");
    }
  }, [dataUser]);

  useEffect(() => {
    if (userAuth !== null) {
      updateEmail(true);
    }
  }, [userAuth]);

  async function updateEmail(action) {
    let uid = "";
    let email = "";
    if (action) {
      uid = userAuth.uid;
      email = userAuth.email;
    }

    setLoader(true);
    const payload = new FormData();
    payload.append("uidEmail", uid);
    payload.append("email", email);
    const result = await Service.user._update(payload);
    setLoader(false);
    if (result.status) {
      const newData = {
        email: email,
        fullName: dataUser.fullName,
        name: dataUser.name,
        profile: dataUser.profile,
        reset: dataUser.reset,
        user: dataUser.user,
        whatsapp: dataUser.whatsapp,
        fullName: dataUser.fullName,
      };

      setDataUser(newData);

      if (action) {
        new Swalert().success("Berhasil Tautkan ke " + userAuth.email);
        setInterval(function () {
          return logOut();
        }, 500);
      } else {
        return new Swalert().success("Success Hapus Tautan");
      }
    }
  }

  async function actionGmail() {
    if (dataUser.email === "") {
      logIn();
    } else {
      const swal = await new Swalert().confirm(
        "Hapus Tautan " + dataUser.email + " ?",
        "Yes"
      );
      if (swal) {
        return updateEmail(false);
      }
    }
  }

  function hideAllLayout() {
    setHideUserLogin("none");
    setHideUserPassword("none");
    setHideUserDetail("none");
    setHideUserProfile("none");
    setHideNewLoginPassword("none");
  }

  async function openModal(action) {
    if (dataUser.reset) {
      setCloseModal(false);
    } else {
      setCloseModal(true);
    }
    setAction(action);
    if (action === actUserLogin) {
      hideAllLayout();
      setHideUserLogin("block");

      setModalTitle("Update User Login");
      setUserLogin("");
      setUserLoginBaru("");
      setPassword("");
    } else if (action === actPassword) {
      hideAllLayout();
      setHideUserPassword("block");

      setModalTitle("Update Password Login");
      setPasswordBaru("");
      setUlangiPasswordBaru("");
      setPassword("");
    } else if (action === actDetail) {
      hideAllLayout();
      setHideUserDetail("block");

      setModalTitle("Update User Detail");

      setLoader(true);
      const payload = new FormData();
      const result = await Service.user._read(payload);
      setLoader(false);

      setNamaLengkap(result.data.name);

      optionsGender.map((item) => {
        if (result.data.gender === item.value) {
          setJenisKelamin(item);
        }
      });

      optionsKab.map((item) => {
        if (result.data.temLahir === item.value) {
          setTempatLahir(item);
        }
      });

      setTanggalLahir(result.data.tglLahir);
      setNoWhatsapp(result.data.whatsapp);
    } else if (action === actProfile) {
      hideAllLayout();
      setHideUserProfile("block");

      setModalTitle("Update Profile");
    } else if (action === actLoginPassword) {
      hideAllLayout();
      setHideNewLoginPassword("block");

      setModalTitle("Created User Login");
      setUserLoginBaru("");
      setPasswordBaru("");
      setCloseModal(false);
    }
    setModal(true);
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  function updateNotify(form, row, notify) {
    if (form.current !== null) {
      const label = form.current.childNodes[row].childNodes[0];
      const input = form.current.childNodes[row].childNodes[1];
      const danger = form.current.childNodes[row].childNodes[2];
      if (notify !== "") {
        danger.style.display = "block";
        danger.innerHTML = notify;
        input.focus();
      } else {
        danger.style.display = "none";
        danger.innerHTML = notify;
      }
    }
  }

  async function submitHandler() {
    async function saveNewLogin() {
      setLoader(true);
      const payload = new FormData();
      payload.append("username", userLoginBaru);
      payload.append("password", password);
      const result = await Service.user._userLogin(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        setDataUser(result.data);
        new Swalert().success("Update Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
    async function saveNewPassword() {
      setLoader(true);
      const payload = new FormData();
      payload.append("passbaru", passwordBaru);
      payload.append("password", password);
      const result = await Service.user._password(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        new Swalert().success("Update Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
    async function saveNewDetail() {
      setLoader(true);
      const payload = new FormData();
      payload.append("name", namaLengkap);
      payload.append("gender", jenisKelamin.value);
      payload.append("temLahir", tempatLahir.value);
      payload.append("tglLahir", tanggalLahir);
      payload.append("whatsapp", noWhatsapp);

      const result = await Service.user._update(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        setDataUser(result.data);
        new Swalert().success("Update Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
    async function saveNewLoginPassword() {
      setLoader(true);
      const payload = new FormData();
      payload.append("userLoginBaru", userLoginBaru);
      payload.append("passwordBaru", passwordBaru);
      const result = await Service.user._newLogin(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        setDataUser(result.data);
        new Swalert().success("Update Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }

    if (action === actUserLogin) {
      const form = refUserLogin;
      if (userLogin === "") {
        return updateNotify(form, 0, "User login lama tidak boleh kosong");
      } else if (userLogin !== dataUser.user) {
        return updateNotify(form, 0, "User login lama salah");
      } else if (userLoginBaru === "") {
        return updateNotify(form, 1, "User login baru tidak boleh kosong");
      } else if (password === "") {
        return updateNotify(form, 2, "Password tidak boleh kosong");
      } else {
        return saveNewLogin();
      }
    } else if (action === actPassword) {
      const form = refUserPassword;
      if (passwordBaru === "") {
        return updateNotify(form, 0, "Password baru tidak boleh kosong");
      } else if (ulangiPasswordBaru === "") {
        return updateNotify(form, 1, "Password baru tidak boleh kosong");
      } else if (passwordBaru !== ulangiPasswordBaru) {
        return updateNotify(form, 1, "Password baru tidak sama");
      } else if (password === "") {
        return updateNotify(form, 2, "Password tidak boleh kosong");
      } else {
        return saveNewPassword();
      }
    } else if (action === actDetail) {
      const form = refUserDetail;
      if (namaLengkap === "") {
        return updateNotify(form, 0, "Nama lengkap belum di isi");
      } else if (jenisKelamin === "") {
        return updateNotify(form, 1, "Jenis kelamin belum di pilih");
      } else if (tempatLahir === "") {
        return updateNotify(form, 2, "Tempat lahir belum di pilih");
      } else if (tanggalLahir === "0000-00-00") {
        return updateNotify(form, 3, "Tanggal lahir belum di pilih");
      } else if (noWhatsapp === "62") {
        return updateNotify(form, 4, "Nomor Whatsapp belum di isi");
      }
      saveNewDetail();
    } else if (action === actProfile) {
      saveNewProfile();
    } else if (action === actLoginPassword) {
      const form = refNewLoginPassword;
      if (userLoginBaru === "") {
        return updateNotify(form, 0, "User login baru tidak boleh kosong");
      } else if (passwordBaru === "") {
        return updateNotify(form, 1, "Password baru tidak boleh kosong");
      } else {
        return saveNewLoginPassword();
      }
    }
  }
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const clickSelect = (e) => {
    refFile.current.click();
  };
  const selectFile = (e) => {
    if (e.target.files[0]) {
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveNewProfile = useCallback(async () => {
    try {
      const blobImage = await getCroppedImg(
        imageCrop,
        croppedAreaPixels,
        rotation
      );
      saveImageCrop(blobImage);
    } catch (e) {
      return new Swalert().warning("Please, select new image");
    }
  }, [croppedAreaPixels, rotation, imageCrop]);

  const saveImageCrop = async (blobImage) => {
    let payload = new FormData();
    payload.append("image", blobImage);

    setLoader(true);
    const result = await Service.user._profile(payload);
    setLoader(false);

    if (result.status) {
      setModal(false);
      setDataUser(result.data);
      new Swalert().success("Update Profile Success");
    }
  };

  function setFormUserLogin() {
    return (
      <Form ref={refUserLogin} style={{ display: hideUserLogin }}>
        <InputLabel
          title="User Login Lama"
          value={userLogin}
          onChange={(e) => {
            setUserLogin(e.target.value);
            updateNotify(refUserLogin, 0, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="User Login Baru"
          value={userLoginBaru}
          onChange={(e) => {
            setUserLoginBaru(e.target.value);
            updateNotify(refUserLogin, 1, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Password"
          type={"password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            updateNotify(refUserLogin, 2, "");
          }}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function setFormUserPassword() {
    return (
      <Form ref={refUserPassword} style={{ display: hideUserPassword }}>
        <InputLabel
          title="Password Baru"
          type={"password"}
          value={passwordBaru}
          onChange={(e) => {
            setPasswordBaru(e.target.value);
            updateNotify(refUserPassword, 0, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Ulangi Password Baru"
          type={"password"}
          value={ulangiPasswordBaru}
          onChange={(e) => {
            setUlangiPasswordBaru(e.target.value);
            updateNotify(refUserPassword, 1, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Password Lama"
          type={"password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            updateNotify(refUserPassword, 2, "");
          }}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function setFormUserDetail() {
    return (
      <Form ref={refUserDetail} style={{ display: hideUserDetail }}>
        <InputLabel
          title="Nama Lengkap"
          value={namaLengkap}
          onChange={(e) => {
            setNamaLengkap(e.target.value);
            updateNotify(refUserDetail, 0, "");
          }}
          onKeyDown={handleKeyDown}
        />

        <SelectLabel
          title={"Jenis Kelamin"}
          isSearchable={false}
          defaultValue={jenisKelamin}
          onChange={(e) => {
            setJenisKelamin(e);
            updateNotify(refUserDetail, 1, "");
          }}
          options={optionsGender}
        />
        <SelectLabel
          title={"Tempat"}
          isSearchable={true}
          defaultValue={tempatLahir}
          onChange={(e) => {
            setTempatLahir(e);
            updateNotify(refUserDetail, 2, "");
          }}
          options={optionsKab}
        />

        <InputLabel
          title={"Tanggal Lahir"}
          type="date"
          value={tanggalLahir}
          onChange={(e) => {
            setTanggalLahir(e.target.value);
            updateNotify(refUserDetail, 3, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <PhoneLabel
          title="Nomor Whatsapp"
          value={noWhatsapp}
          onChange={(e) => {
            setNoWhatsapp(e);
            updateNotify(refUserDetail, 4, "");
          }}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function setFormUserProfile() {
    return (
      <Form style={{ display: hideUserProfile }}>
        <Button style={{ marginBottom: "10px" }} onClick={() => clickSelect()}>
          <Span>Select File</Span>
        </Button>
        <input
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          style={{ height: "0", overflow: "hidden" }}
          ref={refFile}
          onChange={selectFile}
        />
        <div>
          <div
            style={{
              paddingBottom: "100px",
              position: "relative",
              border: "1px",
              borderStyle: "solid",
            }}
          >
            <div style={{ minHeight: "300px", minWidth: "300px" }}>
              <Cropper
                image={imageCrop}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                aspect={4 / 5}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "800px",
                position: "absolute",
              }}
            ></div>
          </div>

          <div>
            <br></br>
            <label>Rotate</label>
            <Form.Range
              value={rotation}
              min={0}
              max={360}
              step={0.1}
              onChange={(e) => setRotation(e.target.value)}
            />
            <label>Zoom</label>
            <Form.Range
              value={zoom}
              min={1}
              max={3}
              step={0.01}
              onChange={(e) => setZoom(e.target.value)}
            />
          </div>
        </div>
      </Form>
    );
  }
  function setFormNewLoginPassword() {
    return (
      <Form ref={refNewLoginPassword} style={{ display: HideNewLoginPassword }}>
        <InputLabel
          title="User Login Baru"
          value={userLoginBaru}
          onChange={(e) => {
            setUserLoginBaru(e.target.value);
            updateNotify(refNewLoginPassword, 0, "");
          }}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Password Baru"
          value={passwordBaru}
          onChange={(e) => {
            setPasswordBaru(e.target.value);
            updateNotify(refNewLoginPassword, 1, "");
          }}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function setLayoutModal() {
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton={closeModal}>
            <Modal.Title>
              <b>{modalTitle}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {setFormUserLogin()}
            {setFormUserPassword()}
            {setFormUserDetail()}
            {setFormUserProfile()}
            {setFormNewLoginPassword()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={submitHandler}>
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }

  return (
    <Layout loader={loader}>
      <Div style={{ width: "100%", height: "100%", margin: "15px" }}>
        <Div className="d-flex flex-column align-items-center text-center">
          <Image
            onClick={() => openModal(actProfile)}
            src={dataUser.profile === "" ? profile : dataUser.profile}
            alt="img"
            style={{ width: "150px", borderRadius: "5px", cursor: "pointer" }}
          />
          <Form.Control
            hidden
            type="file"
            accept="image/png, image/jpeg"
            style={{ height: "0", overflow: "hidden" }}
          />
          <Div className="mt-3">
            <p className="text-muted font-size-sm">{dataUser.user}</p>
            <h4 style={{ marginBottom: "30px" }}>{dataUser.fullName}</h4>
            <Button
              onClick={actionGmail}
              variant="outline-primary"
              style={{ padding: "5px 5px 5px 18px", marginRight: "15px" }}
            >
              <Icon>{iconEmail}</Icon>
            </Button>
            <Button
              onClick={() => openModal(actUserLogin)}
              variant="outline-primary"
              style={{ padding: "5px 5px 5px 18px" }}
            >
              <Icon>key</Icon>
            </Button>
            <Button
              onClick={() => openModal(actPassword)}
              variant="outline-primary"
              style={{
                padding: "5px 5px 5px 18px",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <Icon>lock</Icon>
            </Button>
            <Button
              onClick={() => openModal(actDetail)}
              variant="outline-primary"
              style={{ padding: "5px 5px 5px 18px" }}
            >
              <Icon>edit_square</Icon>
            </Button>
          </Div>
        </Div>
      </Div>
      {setLayoutModal()}
    </Layout>
  );
}
export default User;
