import { AES, enc } from "crypto-js";
import { config } from "./StaticString";

class CriptoJS {
  encript(value) {
    const res = AES.encrypt(JSON.stringify(value), config.cryptoScreet);
    const val = res.toString();
    return val;
  }
  decript(value) {
    try {
      let bytes = AES.decrypt(value, config.cryptoScreet);
      const val = bytes.toString(enc.Utf8);
      return JSON.parse(val);
    } catch (err) {
      console.log("UNABLE TO DECIPHER", err);
    }
  }
}

export default CriptoJS;
