import axios from "axios";

import Swalert from "./../../components/Swalert";
import { config } from "../StaticString";
import JWToken from "./../JWToken";

const baseUrl = config.baseUrl + "auth/";

const _userName = async (payload) => {
  return await actionPost(payload, baseUrl + "userName");
};
const _keyRand = async (payload) => {
  return await actionPost(payload, baseUrl + "keyRand");
};
const _gMail = async (payload) => {
  return await actionPost(payload, baseUrl + "gMail");
};

const actionPost = async (payload, urlBackend) => {
  try {
    const req = await axios.post(urlBackend, payload);
    if (req.data.status === "succes_login") {
      new JWToken()._setJWToken(req.data.jwt.token, req.data.jwt.expired);
    }
    return { req: true, data: req.data };
  } catch (e) {
    new Swalert().error(e + "\n" + e.response.data.error);
    return { req: false, data: e };
  }
};

const auth = { _userName, _keyRand, _gMail };
export { auth };
