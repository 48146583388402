import axios from "axios";

import Swalert from "./../../components/Swalert";
import { config } from "../StaticString";
import JWToken from "./../JWToken";

const baseUrl = config.baseUrl + "user/";

const _read = async (payload) => {
  return await actionPost(payload, baseUrl + "read");
};
const _update = async (payload) => {
  return await actionPost(payload, baseUrl + "update");
};
const _newLogin = async (payload) => {
  return await actionPost(payload, baseUrl + "newLogin");
};
const _userLogin = async (payload) => {
  return await actionPost(payload, baseUrl + "userLogin");
};
const _password = async (payload) => {
  return await actionPost(payload, baseUrl + "password");
};
const _profile = async (payload) => {
  return await actionPost(payload, baseUrl + "profile");
};

const actionPost = async (payload, urlBackend) => {
  try {
    payload.append("token", new JWToken()._getValueJWToken());
    const req = await axios.post(urlBackend, payload);
    const response = req.data;
    new JWToken()._setJWToken(response.jwt.token, response.jwt.expired);
    return response.values;
  } catch (e) {
    const swal = await new Swalert().error(e + "\n" + e.response.data.error);
    if (swal) {
      return { status: false, data: e.response.data.error };
    }
  }
};

const user = {
  _read,
  _update,
  _profile,
  _newLogin,
  _userLogin,
  _password,
};
export { user };
