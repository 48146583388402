import "react-perfect-scrollbar/dist/css/styles.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import UseLocalStorage from "./utils/UseLocalStorage";
import { config, pageMenu, storage } from "./utils/StaticString";
import JWToken from "./utils/JWToken";
import CriptoJS from "./utils/CriptoJS";

import NotFound from "./pages/ForMe/NotFound";
import SignIn from "./pages/Auth/SignIn";
import Home from "./pages/ForMe/Home";
import User from "./pages/ForMe/User";
import Menus from "./pages/ForMe/Menus";

import M_Organization from "./pages/Master/Organization";
import M_Access from "./pages/Master/Access";
import M_Servers from "./pages/Master/Servers";
import M_Users from "./pages/Master/Users";

import L_Users from "./pages/List/Users";
import L_Inject from "./pages/List/Inject";
import L_Whatsapp from "./pages/List/Whatsapp";
import L_Trafik from "./pages/List/Trafik";
import L_Xray from "./pages/List/Xray";

import Refferal from "./pages/Auth/Refferal";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<CheckData />} />
        <Route path={pageMenu.d_sigIn} element={<SignIn />} />
        <Route path={pageMenu.d_sigIn + "/:ref"} element={<Refferal />} />
        <Route path={pageMenu.d_home} element={<Home />} />
        <Route path={pageMenu.d_user} element={<User />} />
        <Route path={pageMenu.d_menus} element={<Menus />} />
        <Route path={pageMenu.m_org} element={<M_Organization />} />
        <Route path={pageMenu.m_access} element={<M_Access />} />
        <Route path={pageMenu.m_users} element={<M_Users />} />
        <Route path={pageMenu.m_server} element={<M_Servers />} />
        <Route path={pageMenu.l_users} element={<L_Users />} />
        <Route path={pageMenu.l_inject} element={<L_Inject />} />
        <Route path={pageMenu.l_xray} element={<L_Xray />} />
        <Route path={pageMenu.l_tarfik} element={<L_Trafik />} />
        <Route path={pageMenu.l_whatsapp} element={<L_Whatsapp />} />
        <Route path={"/*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function CheckData() {
  function redirect(params) {
    window.location.href = params;
  }

  const [accessMenu] = UseLocalStorage(storage.accessMenu, "");
  const [dataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [dataUser] = UseLocalStorage(storage.dataUser, "");
  const cekAuth = new JWToken()._getStatusJWToken();
  if (cekAuth) {
    const access = new CriptoJS().decript(accessMenu);
    if (dataOrg.orgName === config.orgName) {
      for (let index = 0; index < access.length; index++) {
        const item = access[index];
        if (item.path === pageMenu.m_org) {
          return redirect(pageMenu.m_org);
        }
      }
    }
    if (dataUser.reset) {
      return redirect(pageMenu.d_user);
    } else if (dataUser.user === "") {
      return redirect(pageMenu.d_user);
    }
    return redirect(pageMenu.d_home);
  }
  return redirect(pageMenu.d_sigIn);
}
