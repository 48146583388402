import { useEffect, useState } from "react";
import { config, storage } from "../../utils/StaticString";
import UseLocalStorage from "../../utils/UseLocalStorage";
import Theme from "../../utils/Theme";
import LoadingOverlay from "react-loading-overlay";
import { Helmet } from "react-helmet";
import { loginBg, registerBg } from "../../assets";

export default function PageLayout({ children, loader, type }) {
  if (loader === undefined) {
    loader = false;
  }
  let classBody =
    "app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end";

  let styleBack = {
    background: "url(" + loginBg + ") no-repeat",
    backgroundSize: "60%",
    backgroundPosition: " center",
  };

  if (type === "signUp") {
    classBody =
      "app app-auth-sign-up align-content-stretch d-flex flex-wrap justify-content-end";
    styleBack = {
      background: "url(" + registerBg + ") no-repeat",
      backgroundSize: "60%",
      backgroundPosition: " center",
    };
  }
  const [theme] = UseLocalStorage(storage.themes, "light_mode");
  const [dataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [darkTheme, setDark] = useState("");

  useEffect(() => {
    if (theme === "dark_mode") {
      setDark(Theme.Dark_Mode());
    } else {
      setDark(Theme.Light_Mode());
    }
  }, [theme]);

  return (
    <>
      <LoadingOverlay
        active={loader}
        spinner
        styles={{
          wrapper: {
            overflow: "none",
          },
        }}
      />
      <Helmet>
        <title>{dataOrg === "" ? config.orgName : dataOrg.orgName}</title>
      </Helmet>
      {darkTheme}
      <div className={classBody}>
        <div className="app-auth-background" style={styleBack}></div>
        <div className="app-auth-container">{children}</div>
      </div>
    </>
  );
}
