import { useEffect, useRef, useState } from "react";
import Access from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import { Tab, Tabs } from "react-bootstrap";
import Div from "../../components/Div";
import DatePickerLabel from "../../components/DatePickerLabel";
import ChartApex from "../../components/ChartApex";
import SelectLabel from "../../components/SelectLabel";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import BytesToSize from "../../utils/BytesToSize";

function Trafik() {
  const { actCreated, actUpdate, actDelete } = Access();
  const [loader, setLoader] = useState(false);

  const [dayAkun, setDayAkun] = useState(null);
  const [monthAkun, setMonthAkun] = useState(null);
  const [optionsAkun, setOptionsAkun] = useState(null);

  const [dayRange, setDayRange] = useState(null);
  const [montRange, setMontRange] = useState(null);

  const [totalDay, setTotalDay] = useState("total : 0GB");
  const [totalMonth, setTotalMonth] = useState("total : 0GB");

  const [headerDay, setHeaderDay] = useState("");
  const [categoriesDay, setCategoriesDay] = useState([]);
  const [seriesDay, setSeriesDay] = useState([]);

  const [headerMonth, setHeaderMonth] = useState("");
  const [categoriesMonth, setCategoriesMonth] = useState([]);
  const [seriesMonth, setSeriesMonth] = useState([]);

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  useEffect(() => {
    dayRequest();
  }, [dayRange, dayAkun]);

  useEffect(() => {
    monthRequest();
  }, [montRange, monthAkun]);

  async function updateListData() {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.list.trafik._read(payload);
    setLoader(false);
    if (result.status) {
      setOptionsAkun(result.data);
    }
  }

  async function dayRequest() {
    if (dayAkun === null || dayRange === null) {
      return;
    }
    setLoader(true);
    const payload = new FormData();
    payload.append("idXray", dayAkun.value);
    payload.append("dateFrom", dayRange[0]);
    payload.append("dateTo", dayRange[1]);
    const result = await Service.list.trafik._day(payload);
    setLoader(false);
    if (result.status) {
      setHeaderDay(result.data.header);
      setCategoriesDay(result.data.categories);
      setSeriesDay(result.data.series);
      const categories = result.data.categories;
      if (categories.length <= 0) {
        new Swalert().warning("data tidak ada untuk di tampilkan");
      }
      const arrData = result.data.series;
      var totalData = 0;
      for (let index = 0; index < arrData[0].data.length; index++) {
        const gb = arrData[0].data[index];
        totalData = totalData + parseFloat(gb);
      }
      setTotalDay("total : " + BytesToSize(totalData));
    }
  }
  async function monthRequest() {
    if (monthAkun === null || montRange === null) {
      return;
    }
    setLoader(true);
    const payload = new FormData();
    payload.append("idXray", monthAkun.value);
    payload.append("dateFrom", montRange[0]);
    payload.append("dateTo", montRange[1]);
    const result = await Service.list.trafik._month(payload);
    setLoader(false);
    if (result.status) {
      setHeaderMonth(result.data.header);
      setCategoriesMonth(result.data.categories);
      setSeriesMonth(result.data.series);
      const categories = result.data.categories;
      if (categories.length <= 0) {
        new Swalert().warning("data tidak ada untuk di tampilkan");
      }
      const arrData = result.data.series;
      var totalData = 0;
      for (let index = 0; index < arrData[0].data.length; index++) {
        const gb = arrData[0].data[index];
        totalData = totalData + parseFloat(gb);
      }
      setTotalMonth("total : " + BytesToSize(totalData));
    }
  }
  function optionsLabel(item) {
    if (item.value === "all") {
      return "Semua : " + item.label;
    } else if (item.zerotier === null) {
      return "Xray : " + item.label;
    } else {
      return "Inject : " + item.label;
    }
  }
  return (
    <Layout loader={loader}>
      <Div style={{ margin: "5px" }}>
        <Tabs defaultActiveKey="1" transition={true}>
          <Tab eventKey="1" title="HARIAN">
            <Div className="mt-4">
              <SelectLabel
                title={"Pilih Akun"}
                isSearchable={false}
                onChange={(e) => {
                  setDayAkun(e);
                }}
                options={optionsAkun}
                getOptionLabel={(item) => optionsLabel(item)}
              />
              <DatePickerLabel
                range={true}
                onChange={setDayRange}
                title="Pilih Rentang Tanggal"
              />
              <Div>{totalDay}</Div>
            </Div>
            <ChartApex
              byte={true}
              header={headerDay}
              categories={categoriesDay}
              series={seriesDay}
            />
          </Tab>
          <Tab eventKey="2" title="BULANAN">
            <Div className="mt-4">
              <SelectLabel
                title={"Pilih Akun"}
                isSearchable={false}
                onChange={(e) => {
                  setMonthAkun(e);
                }}
                options={optionsAkun}
                getOptionLabel={(item) => optionsLabel(item)}
              />
              <DatePickerLabel
                range={true}
                onlyMonthPicker={true}
                onChange={setMontRange}
                title="Pilih Rentang Bulan"
              />
              <Div>{totalMonth}</Div>
            </Div>
            <ChartApex
              byte={true}
              header={headerMonth}
              categories={categoriesMonth}
              series={seriesMonth}
            />
          </Tab>
        </Tabs>
      </Div>
    </Layout>
  );
}
export default Trafik;
