import ReactApexChart from "react-apexcharts";
import BytesToSize from "../utils/BytesToSize";

const ChartApex = ({ categories, series, header, byte = false }) => {
  if (header === undefined) {
    header = "INI HEADER";
  }
  if (series === undefined) {
    series = [
      {
        name: "series-1",
        data: [30, 40, 25, 50, 49, 21, 70, 51],
      },
      {
        name: "series-2",
        data: [23, 12, 54, 61, 32, 56, 81, 19],
      },
    ];
  }
  if (categories === undefined) {
    categories = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  }

  const options = {
    xaxis: {
      categories: categories,
    },
    yaxis: {
      show: false,
      labels: {
        show: true,
        formatter: function (val) {
          if (byte) {
            return BytesToSize(val);
          } else {
            return val;
          }
        },
      },
    },
    tooltip: {
      followCursor: true,
      y: {
        formatter: function (val) {
          if (byte) {
            return BytesToSize(val);
          } else {
            return val;
          }
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (byte) {
          return BytesToSize(val);
        } else {
          return val;
        }
      },
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: "#9a9cab33",
      },
    },
    chart: {
      height: 380,
      type: "area",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: header,
      align: "center",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#9a9cab",
      },
    },
    grid: {
      borderColor: "#9a9cab33",
      row: {
        colors: ["#9a9cab33", "transparent"],
        opacity: 0.3,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#0000ff"],
        type: "horizontal",
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="380"
      stacked={false}
      toolbar={false}
    />
  );
};

export default ChartApex;
