import { createRef, useCallback, useEffect, useState } from "react";
import AccessMenu from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { IdnKab, profile } from "../../assets";
import { Service } from "../../utils/service";
import Swalert from "../../components/Swalert";
import getCroppedImg from "../../components/cropped/Crop";
import { config } from "../../utils/StaticString";
import {
  Button,
  ButtonGroup,
  Form,
  Image,
  Modal,
  ToggleButton,
} from "react-bootstrap";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import Tables from "../../components/Tables";
import Div from "../../components/Div";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import Cropper from "react-easy-crop";
import JWToken from "../../utils/JWToken";

function Users() {
  const { actCreated, actUpdate, actDelete } = AccessMenu();
  const [loader, setLoader] = useState(false);

  const [listTable, setListTable] = useState([]);

  const refFile = createRef();
  const [modalProfile, setModalProfile] = useState(false);
  const [itemProfile, setItemProfile] = useState("");
  const [imageCrop, setImageCrop] = useState("");

  const [modal, setModal] = useState(false);
  const [action, setAction] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const refUserDetail = createRef();
  const [idUser, setIdUser] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [noWhatsapp, setNoWhatsapp] = useState("");

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  const optionsGender = [
    { value: "male", label: "Laki Laki" },
    { value: "female", label: "Perempuan" },
  ];

  const optionsKab = [];
  new IdnKab().getAllData().map((item) => {
    const newItem = { value: item.indID, label: item.indName };
    optionsKab.push(newItem);
  });

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.list.users._read(payload);
    setLoader(false);
    if (result.status) {
      setListTable(result.data);
    }
  };

  async function openModal(item) {
    setAction(item);
    if (item === "") {
      setModalTitle("Add New User");
      setNamaLengkap("");
      setJenisKelamin("");
      setTempatLahir("");
      setTanggalLahir("");
      setNoWhatsapp("");
    } else {
      setModalTitle("Update User Detail");
      setIdUser(item.id);
      setNamaLengkap(item.name);
      optionsGender.map((itemG) => {
        if (item.gender === itemG.value) {
          setJenisKelamin(itemG);
        }
      });

      optionsKab.map((itemk) => {
        if (parseInt(item.temLahir) === itemk.value) {
          setTempatLahir(itemk);
        }
      });

      setTanggalLahir(item.tglLahir);
      setNoWhatsapp(item.whatsapp);
    }
    setModal(true);
  }

  function updateNotify(form, row, notify) {
    if (form.current !== null) {
      const label = form.current.childNodes[row].childNodes[0];
      const input = form.current.childNodes[row].childNodes[1];
      const danger = form.current.childNodes[row].childNodes[2];
      if (notify !== "") {
        danger.style.display = "block";
        danger.innerHTML = notify;
        input.focus();
      } else {
        danger.style.display = "none";
        danger.innerHTML = notify;
      }
    }
  }
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  async function submitHandler() {
    async function saveNewData() {
      setLoader(true);
      const payload = new FormData();
      payload.append("name", namaLengkap);
      payload.append("gender", jenisKelamin.value);
      payload.append("temLahir", tempatLahir.value);
      payload.append("tglLahir", tanggalLahir);
      payload.append("whatsapp", noWhatsapp);

      const result = await Service.list.users._created(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        const newData = {
          id: result.data[0],
          refferal: result.data[1],
          name: namaLengkap,
          gender: jenisKelamin.value,
          temLahir: tempatLahir.value,
          tglLahir: tanggalLahir,
          whatsapp: noWhatsapp,
          images: "",
        };

        listTable.push(newData);
        new Swalert().success("Created Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }
    async function updateData() {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", idUser);
      payload.append("name", namaLengkap);
      payload.append("gender", jenisKelamin.value);
      payload.append("temLahir", tempatLahir.value);
      payload.append("tglLahir", tanggalLahir);
      payload.append("whatsapp", noWhatsapp);
      const result = await Service.list.users._update(payload);
      setLoader(false);
      if (result.status) {
        setModal(false);
        const newData = {
          id: idUser,
          name: namaLengkap,
          gender: jenisKelamin.value,
          temLahir: tempatLahir.value,
          tglLahir: tanggalLahir,
          whatsapp: noWhatsapp,
          refferal: result.data,
          images: action.images === "" ? "" : action.images,
        };

        listTable.map((item, index) => {
          if (item.id === idUser) {
            listTable[index] = newData;
          }
        });
        setListTable(listTable);
        new Swalert().success("Update Success");
      } else {
        return new Swalert().warning(result.data);
      }
    }

    const form = refUserDetail;
    if (namaLengkap === "") {
      return updateNotify(form, 0, "Nama lengkap belum di isi");
    } else if (jenisKelamin === "") {
      return updateNotify(form, 1, "Jenis kelamin belum di pilih");
    } else if (tempatLahir === "") {
      return updateNotify(form, 2, "Tempat lahir belum di pilih");
    } else if (tanggalLahir === "0000-00-00" || tanggalLahir === "") {
      return updateNotify(form, 3, "Tanggal lahir belum di pilih");
    } else if (noWhatsapp === "62" || noWhatsapp === "") {
      return updateNotify(form, 4, "Nomor Whatsapp belum di isi");
    }

    if (action === "") {
      saveNewData();
    } else {
      updateData();
    }
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete User " + item.name, "Yes");
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      const result = await Service.list.users._delete(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === item.id) {
            listTable.splice(index, 1);
            setListTable(listTable);
            return new Swalert().success("Delete " + item.name + " Success");
          }
        }
      }
    }
  }
  function shareRefferalLink(item) {}

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const clickSelect = (e) => {
    refFile.current.click();
  };
  const selectFile = (e) => {
    if (e.target.files[0]) {
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveNewProfile = useCallback(async () => {
    try {
      const blobImage = await getCroppedImg(
        imageCrop,
        croppedAreaPixels,
        rotation
      );
      saveImageCrop(blobImage);
    } catch (e) {
      return new Swalert().warning("Please, select new image");
    }
  }, [croppedAreaPixels, rotation, imageCrop]);

  const saveImageCrop = async (blobImage) => {
    let payload = new FormData();
    payload.append("id", itemProfile.id);
    payload.append("image", blobImage);

    const result = await Service.list.users._profile(payload);
    setLoader(false);
    if (result.status) {
      setModalProfile(false);

      listTable.map((item) => {
        if (item.id === itemProfile.id) {
          item.images = result.data;
        }
      });
      setListTable(listTable);
      new Swalert().success("Update Success");
    } else {
      return new Swalert().warning(result.data);
    }
  };

  function setLayoutTable() {
    function image(item) {
      return (
        <Image
          src={item.images === "" ? profile.src : config.baseUrl + item.images}
          alt="img"
          onClick={(e) => {
            setImageCrop(
              item.images === "" ? profile.src : config.baseUrl + item.images
            );
            setItemProfile(item);
            setModalProfile(true);
          }}
          style={{ width: "30px", cursor: "pointer" }}
        />
      );
    }
    function action(item) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const localhost = protocol + "//" + host + "/";
      const disabled = parseInt(item.id) === 1 ? true : false;
      return (
        <ButtonGroup>
          <ToggleButton
            disabled={disabled}
            key={randNumb()}
            variant={disabled ? "outline" : "outline-danger"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => deleteItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>delete</Icon>
          </ToggleButton>
          <RWebShare
            data={{
              title: item.name,
              url: localhost + "sigIn/" + item.refferal,
            }}
          >
            <ToggleButton
              disabled={disabled}
              key={randNumb()}
              variant={disabled ? "outline" : "outline-primary"}
              style={{ padding: "3px 3px 3px 5px" }}
            >
              <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>share</Icon>
            </ToggleButton>
          </RWebShare>
        </ButtonGroup>
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function dateFormat(date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "-" + mm + "-" + yyyy;
      return formattedToday;
    }
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td>{image(item)}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => openModal(item)}
            >
              {capitalizeFirstLetter(item.name)}
            </Tables.Td>
            <Tables.Td>
              {optionsGender.map((itemG) => {
                if (item.gender === itemG.value) {
                  return itemG.label;
                }
              })}
            </Tables.Td>
            <Tables.Td>
              {new IdnKab().getAllData().map((itemK) => {
                if (itemK.indID === parseInt(item.temLahir)) {
                  return capitalizeFirstLetter(
                    itemK.indName.replace("KOTA ", "").replace("KAB. ", "")
                  );
                }
              })}
              {", "}
              <br></br>
              {dateFormat(item.tglLahir)}
            </Tables.Td>
            <Tables.Td>{item.whatsapp}</Tables.Td>
            {actDelete ? <Tables.Td>{action(item)}</Tables.Td> : ""}
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>PROFILE</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>JENIS KELAMIN</Tables.Th>
            <Tables.Th>TANGGAL LAHIR</Tables.Th>
            <Tables.Th>WHATSAPP</Tables.Th>
            {actDelete ? <Tables.Th>ACTION</Tables.Th> : ""}
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setLayoutModal() {
    let urutForm = 0;
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{modalTitle}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={refUserDetail}>
              <InputLabel
                title="Nama Lengkap"
                value={namaLengkap}
                onChange={(e) => {
                  setNamaLengkap(e.target.value);
                  updateNotify(refUserDetail, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />

              <SelectLabel
                title={"Jenis Kelamin"}
                isSearchable={false}
                defaultValue={jenisKelamin}
                onChange={(e) => {
                  setJenisKelamin(e);
                  updateNotify(refUserDetail, urutForm++, "");
                }}
                options={optionsGender}
              />
              <SelectLabel
                title={"Tempat"}
                isSearchable={true}
                defaultValue={tempatLahir}
                onChange={(e) => {
                  setTempatLahir(e);
                  updateNotify(refUserDetail, urutForm++, "");
                }}
                options={optionsKab}
              />

              <InputLabel
                title={"Tanggal Lahir"}
                type="date"
                value={tanggalLahir}
                onChange={(e) => {
                  setTanggalLahir(e.target.value);
                  updateNotify(refUserDetail, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
              <PhoneLabel
                title="Nomor Whatsapp"
                value={noWhatsapp}
                onChange={(e) => {
                  setNoWhatsapp(e);
                  updateNotify(refUserDetail, urutForm++, "");
                }}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {actUpdate ? (
              <Button variant="outline-primary" onClick={submitHandler}>
                Simpan
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }

  function setLayoutModalProfile() {
    return (
      <Modal
        show={modalProfile}
        onHide={() => setModalProfile(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{itemProfile.name}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {actUpdate ? (
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={() => clickSelect()}
                >
                  <Span>Select File</Span>
                </Button>
              ) : (
                ""
              )}
              <input
                type="file"
                accept="image/png, image/jpeg, image/svg+xml"
                style={{ height: "0", overflow: "hidden" }}
                ref={refFile}
                onChange={selectFile}
              />
              <div>
                <div
                  style={{
                    paddingBottom: "100px",
                    position: "relative",
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <div style={{ minHeight: "300px", minWidth: "300px" }}>
                    <Cropper
                      image={imageCrop}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      zoomSpeed={4}
                      maxZoom={3}
                      zoomWithScroll={true}
                      showGrid={true}
                      aspect={4 / 5}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      onRotationChange={setRotation}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "800px",
                      position: "absolute",
                    }}
                  ></div>
                </div>

                <div>
                  <br></br>
                  <label>Rotate</label>
                  <Form.Range
                    value={rotation}
                    min={0}
                    max={360}
                    step={0.1}
                    onChange={(e) => setRotation(e.target.value)}
                  />
                  <label>Zoom</label>
                  <Form.Range
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.01}
                    onChange={(e) => setZoom(e.target.value)}
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {actUpdate ? (
              <Button variant="outline-primary" onClick={saveNewProfile}>
                Simpan
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }
  return (
    <Layout loader={false}>
      {actCreated ? (
        <Div style={{ margin: "10px" }}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => openModal("")}
          >
            TAMBAH USER
          </Button>
        </Div>
      ) : (
        ""
      )}
      {setLayoutTable()}
      {setLayoutModal()}
      {setLayoutModalProfile()}
    </Layout>
  );
}
export default Users;
