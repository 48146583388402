import { createRef, useEffect, useState } from "react";
import Access from "./../PageLayout/Access";
import Layout from "./../PageLayout/Layout";
import { Service } from "../../utils/service";
import { pageMenu } from "../../utils/StaticString";
import Icon from "../../components/Icon";
import { Button, Form, Modal } from "react-bootstrap";
import Swalert from "../../components/Swalert";
import Tables from "../../components/Tables";
import Div from "../../components/Div";
import InputLabel from "../../components/InputLabel";
import JWToken from "../../utils/JWToken";

function Menus() {
  const { actCreated, actUpdate, actDelete } = Access();

  const [loader, setLoader] = useState(false);
  const [listTable, setListTable] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [idMenu, setIdMenu] = useState("");
  const [label, setLabel] = useState("");
  const [icon, setIcon] = useState("");
  const [path, setPath] = useState("");
  const refFrom = createRef();

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  function randNumb() {
    return Math.floor(Math.random() * 100000000000);
  }

  //ACTION DATA TABLE
  const updateListData = async () => {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.pageMenu._read(payload);
    setLoader(false);
    if (result.status) {
      setListTable(result.data.menu);
    }
  };

  function setLayoutTable() {
    function icon(micon) {
      if (micon !== "") {
        return <Icon style={{ fontSize: "30px" }}>{micon}</Icon>;
      }
    }
    function action(item) {
      let disableButton = true;
      let outlineButton = "outline";
      if (
        item.path !== pageMenu.d_user &&
        item.path !== pageMenu.d_home &&
        item.path !== pageMenu.d_pages &&
        item.path !== pageMenu.m_access
      ) {
        outlineButton = "outline-danger";
        disableButton = false;
      }

      return (
        <Button
          disabled={disableButton}
          onClick={() => deleteItem(item)}
          variant={outlineButton}
          size="sm"
          style={{ padding: "5px 5px 5px 15px" }}
        >
          <Icon style={{ fontSize: "20px" }}>delete</Icon>
        </Button>
      );
    }
    function cekEditItem(item) {
      if (
        item.path !== pageMenu.d_user &&
        item.path !== pageMenu.d_home &&
        item.path !== pageMenu.d_pages &&
        item.path !== pageMenu.m_access
      ) {
        openModal(item);
      } else {
        new Swalert().warning(item.label + " ini paten");
      }
    }
    function dataBody() {
      let no = 0;
      return listTable.map((item) => {
        no++;
        return (
          <Tables.Tr>
            <Tables.Td>{no}</Tables.Td>
            <Tables.Td>
              <Form.Control
                style={{ width: "75px" }}
                type={"number"}
                defaultValue={item.urut}
                onKeyDown={(event) => updateUrutPageMenu(event, item)}
              />
            </Tables.Td>
            <Tables.Td>{icon(item.icon)}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => cekEditItem(item)}
            >
              {item.label}
            </Tables.Td>
            <Tables.Td style={{ fontAlign: "left" }}>{item.path}</Tables.Td>
            <Tables.Td>{action(item)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>URUT</Tables.Th>
            <Tables.Th>ICON</Tables.Th>
            <Tables.Th>LABEL</Tables.Th>
            <Tables.Th>PATH</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  function setLayoutModal() {
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{modalTitle}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form ref={refFrom}>
              <InputLabel
                title="Material Icon"
                value={icon}
                onChange={(e) => {
                  setIcon(e.target.value);
                  hideNotify(e.target);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Label"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                  hideNotify(e.target);
                }}
                onKeyDown={handleKeyDown}
              />
              <InputLabel
                title="Path"
                value={path}
                onChange={(e) => {
                  setPath(e.target.value);
                  hideNotify(e.target);
                }}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={submitHandler}>
              Simpan
            </Button>
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }

  function openModal(item) {
    if (item === "") {
      setModalTitle("Tambah Menu");
      setIdMenu("0");
      setLabel("");
      setPath("");
      setIcon("");
    } else {
      setModalTitle("Edit Menu");
      setIdMenu(item.id);
      setLabel(item.label);
      setIcon(item.icon);
      setPath(item.path);
    }
    setModal(true);
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete " + item.label, "Yes");
    if (swal) {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", item.id);
      const result = await Service.pageMenu._delete(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === item.id) {
            listTable.splice(index, 1);
            setListTable(listTable);
            return new Swalert().success("Delete " + item.label + " Success");
          }
        }
      }
    }
  }

  function showNotify(form, notify) {
    const child = form.parentNode.childNodes;
    child[2].style.display = "block";
    child[2].innerHTML = notify;
    form.focus();
  }
  function hideNotify(form) {
    const child = form.parentNode.childNodes;
    child[2].style.display = "none";
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitHandler();
    }
  }

  function submitHandler() {
    if (label === "") {
      const form = refFrom.current;
      return showNotify(form[1], "Label harus di isi.");
    } else if (path === "") {
      const form = refFrom.current;
      return showNotify(form[2], "Path harus di isi.");
    }
    async function addMenu() {
      setLoader(true);
      const payload = new FormData();
      payload.append("label", label);
      payload.append("path", path);
      payload.append("icon", icon);
      const result = await Service.pageMenu._created(payload);
      setLoader(false);

      if (result.status) {
        listTable[listTable.length] = result.data;
        setListTable(listTable);
        setModal(false);
        return new Swalert().success("Add Menu Success");
      } else {
        return new Swalert().warning("Silahkan Ulangi beberapa saat lagi");
      }
    }
    async function updateMenu() {
      setLoader(true);
      const payload = new FormData();
      payload.append("id", idMenu);
      payload.append("label", label);
      payload.append("path", path);
      payload.append("icon", icon);
      const result = await Service.pageMenu._update(payload);
      setLoader(false);
      if (result.status) {
        for (let index = 0; index < listTable.length; index++) {
          const el = listTable[index];
          if (el.id === idMenu) {
            listTable[index].label = label;
            listTable[index].path = path;
            listTable[index].icon = icon;
            setListTable(listTable);
            setModal(false);
            return new Swalert().success("Update Menu Success");
          }
        }
      } else {
        return new Swalert().warning("Silahkan Ulangi beberapa saat lagi");
      }
    }

    if (idMenu === "0") {
      addMenu();
    } else {
      updateMenu();
    }
  }

  async function updateUrutPageMenu(event, item) {
    if (event.key === "Enter") {
      for (let index = 0; index < listTable.length; index++) {
        const el = listTable[index];
        if (el.id === item.id) {
          listTable[index].urut = event.target.value;
          setListTable(listTable);
        }
      }
      const payload = new FormData();
      payload.append("id", item.id);
      payload.append("urut", event.target.value);
      await Service.pageMenu._update(payload);
    }
  }

  return (
    <Layout loader={false}>
      <Div style={{ margin: "10px" }}>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => openModal("")}
        >
          TAMBAH MENU
        </Button>
      </Div>
      {setLayoutTable()}
      {setLayoutModal()}
    </Layout>
  );
}
export default Menus;
