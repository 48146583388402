import PerfectScrollbar from "react-perfect-scrollbar";
import { useEffect } from "react";

import JWToken from "./../../utils/JWToken";
import { pageMenu } from "./../../utils/StaticString";

import List from "./../../components/List";
import Icon from "./../../components/Icon";
import Span from "./../../components/Span";
import Div from "./../../components/Div";
import MenuItem from "./../../components/sidebar/MenuItem";
import MenuColapse from "./../../components/sidebar/MenuColapse";

import { useNavigate } from "react-router-dom";

export default function Sidebar({ props }) {
  const { actSidebar, HeaderUser, setHeader } = props;
  const navigate = useNavigate();

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
    };
  });

  const events = ["mousemove", "keydown"];
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
  };
  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };
  const eventHandler = (eventType) => {
    if (!new JWToken()._getStatusJWToken()) {
      logOut();
    }
  };

  const logOut = () => {
    if (new JWToken()._removeCookie()) {
      navigate("/");
    }
  };

  const openRoute = (slug) => {
    navigate(slug);
    setHeader();
    actSidebar();
  };

  return (
    <>
      <Div className="header">
        <HeaderUser />
      </Div>
      <PerfectScrollbar style={{ height: "80%!important" }}>
        <List.ul className="accordion-menu">
          <Div className="sidebar-title">Menu</Div>
          <MenuItem path={pageMenu.d_user} route={openRoute} />
          <MenuItem path={pageMenu.d_home} route={openRoute} />
          <MenuColapse label={"Master"} icon={"filter_tilt_shift"}>
            <MenuItem path={pageMenu.m_org} route={openRoute} />
            <MenuItem path={pageMenu.m_access} route={openRoute} />
            <MenuItem path={pageMenu.m_users} route={openRoute} />
            <MenuItem path={pageMenu.m_server} route={openRoute} />
          </MenuColapse>
          <MenuColapse label={"List"} icon={"subject"}>
            <MenuItem path={pageMenu.l_users} route={openRoute} />
            <MenuItem path={pageMenu.l_xray} route={openRoute} />
            <MenuItem path={pageMenu.l_inject} route={openRoute} />
            <MenuItem path={pageMenu.l_tarfik} route={openRoute} />
            <MenuItem path={pageMenu.l_whatsapp} route={openRoute} />
          </MenuColapse>
          <Div className="sidebar-title">Other</Div>
          <MenuItem path={pageMenu.d_menus} route={openRoute} />
          <List.li>
            <Div onClick={logOut}>
              <Icon>logout</Icon>
              <Span>Logout</Span>
            </Div>
          </List.li>
        </List.ul>
      </PerfectScrollbar>
      <Div className="footer">
        <Div className="version">
          <b>Version: </b> 1.0.5
        </Div>
        <Div className="copyright">
          <Span>© 2016 - 2017 </Span>
          <a href="#">MIK SIMP</a>.
        </Div>
      </Div>
    </>
  );
}
