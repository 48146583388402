import moment from "moment";
import "moment/locale/id";

const month = (dateOne, dateTwo = new Date()) => {
  moment.locale("id");
  var tanggal1 = new moment(new Date(dateOne));
  var tanggal2 = new moment(new Date(dateTwo));
  var selisih = tanggal1.diff(tanggal2, "month");
  return selisih;
};

const days = (dateOne = new Date(), dateTwo = new Date()) => {
  moment.locale("id");
  var tanggal1 = new moment(new Date(dateOne));
  var tanggal2 = new moment(new Date(dateTwo));
  var selisih = tanggal1.diff(tanggal2, "days");
  return selisih;
};
const dateFrom = (dateOne = new Date(), dateTwo = new Date()) => {
  moment.locale("id");
  var tanggal1 = new moment(new Date(dateOne));
  var tanggal2 = new moment(new Date(dateTwo));
  var selisih = tanggal1.from(tanggal2);
  return selisih;
};
const dateTo = (dateOne = new Date(), dateTwo = new Date()) => {
  moment.locale("id");
  var tanggal1 = new moment(new Date(dateOne));
  var tanggal2 = new moment(new Date(dateTwo));
  var selisih = tanggal1.to(tanggal2);
  return selisih;
};

const DateMoment = {
  month: month,
  days: days,
  dateFrom: dateFrom,
  dateTo: dateTo,
};

export default DateMoment;
