import { createRef, useEffect, useState } from "react";
import { config, storage } from "../../utils/StaticString";
import PageLayout from "./Layout";
import AuthGoogle from "../../utils/AuthGoogle";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Image } from "react-bootstrap";
import CriptoJS from "../../utils/CriptoJS";
import Swalert from "../../components/Swalert";
import { Service } from "../../utils/service";
import { orgLogo } from "../../assets";

const removeAllStorage = async () => {
  try {
    if (typeof window !== "undefined") {
      //window.localStorage.clear();
      const cekStorageUser = window.localStorage.getItem(storage.dataUser);
      if (cekStorageUser !== null) {
        window.localStorage.removeItem(storage.dataUser);
      }

      const cekStorageAccess = window.localStorage.getItem(storage.accessMenu);
      if (cekStorageAccess !== null) {
        window.localStorage.removeItem(storage.accessMenu);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
function SignIn() {
  removeAllStorage();

  const [userAuth, logIn, logOut] = AuthGoogle();
  const [accessMenu, setAccessMenu] = UseLocalStorage(storage.accessMenu, "");
  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, "");
  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, "");
  const [theme, setTheme] = UseLocalStorage(storage.themes, "");

  const [orgIcon, setOrgIcon] = useState("");

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const [alertDialog, setAlertDialog] = useState("");
  const [timeoutID, setTimeoutID] = useState("");

  const refFrom = createRef();
  const navigate = useNavigate();

  useEffect(() => {
    setOrgIcon(dataOrg.orgIcon);
    const form = refFrom.current;
    return form[0].focus();
  }, []);

  useEffect(() => {
    if (userAuth !== null) {
      authEmail();
    }
  }, [userAuth]);

  const updateAlertDialog = (color, notification) => {
    setAlertDialog(
      <Alert key={color} variant={color}>
        {notification}
      </Alert>
    );
  };
  const updateAlertDialogTimer = (color, notification) => {
    updateAlertDialog(color, notification);

    if (timeoutID !== "") {
      clearTimeout(timeoutID);
    }
    const timeoutId = setTimeout(() => {
      setAlertDialog("");
      setTimeoutID("");
    }, 5000);
    setTimeoutID(timeoutId);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };

  async function authEmail() {
    window.localStorage.removeItem(storage.accessMenu);
    setLoader(true);
    const payload = new FormData();
    payload.append("uid", userAuth.uid);
    payload.append("gmail", userAuth.email);
    var result = await Service.auth._gMail(payload);
    setLoader(false);
    setInterval(function () {
      logOut();
    }, 500);
    return resultLogin(result);
  }
  const submitHandler = async () => {
    window.localStorage.removeItem(storage.accessMenu);
    const form = refFrom.current;
    if (userName === "") {
      updateAlertDialogTimer("warning", "Username harus di isi!");
      return form[0].focus();
    }
    if (password === "") {
      updateAlertDialogTimer("warning", "Password harus di isi!");
      return form[1].focus();
    }

    setLoader(true);
    const payload = new FormData();
    payload.append("username", userName);
    payload.append("password", password);
    var result = await Service.auth._userName(payload);
    setLoader(false);
    return resultLogin(result);
  };

  const resultLogin = async (result) => {
    const data = result.data;
    if (result.req) {
      if (data.status === "invalid_user") {
        return updateAlertDialogTimer("danger", data.error);
      } else if (data.status === "invalid_password") {
        const wrongData = data.wrong;
        if (wrongData < 3) {
          return updateAlertDialogTimer("warning", data.error);
        } else {
          return updateAlertDialogTimer("danger", data.error);
        }
      } else if (data.status === "user_locked") {
        accountLocked(data.name, data.limitLogin);
      } else if (data.status === "succes_login") {
        if (data.dataOrg.orgName === "") {
          data.dataOrg.orgName = config.orgName;
        }
        if (data.dataOrg.orgIcon === "") {
          data.dataOrg.orgIcon = config.orgIcon;
        }

        setTheme(data.theme);
        setDataUser(data.dataUser);
        setDataOrg(data.dataOrg);

        setAccessMenu(new CriptoJS().encript(data.dataMenu));
        const swal = await new Swalert().success("Login Berhasil . . .");
        if (swal) {
          navigate("/");
        }
      }
    } else {
      return updateAlertDialogTimer("danger", data.message);
    }
  };

  const accountLocked = (name, limitLogin) => {
    var notify = [
      name,
      <br />,
      "Akun Anda Terkunci",
      <br />,
      "Waktu tunggu . . . .",
    ];

    updateAlertDialog("danger", notify);

    var countDownDate = new Date(limitLogin).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      console.log(minutes, seconds);
      let limit = minutes + "m " + seconds + "s";
      if (minutes <= 0) {
        limit = seconds + "s";
      }
      var notify = [
        name,
        <br />,
        "Akun Anda Terkunci",
        <br />,
        "Waktu tunggu " + limit + "",
      ];
      updateAlertDialog("danger", notify);
      if (distance <= 0) {
        clearInterval(x);
        notify = [name, <br />, "Silahkan Login Kembali"];
        updateAlertDialog("success", notify);
      }
    }, 1000);
  };
  const setIconOrg = () => {
    if (dataOrg === "") {
      return orgLogo;
    } else {
      return orgIcon;
    }
  };

  return (
    <PageLayout loader={loader} type={"sigIn"}>
      <div className="logo">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ padding: "10px", textAlign: "center" }}>
                <Image style={{ height: "120px" }} src={setIconOrg()}></Image>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="auth-description">
        Please sign-in to your account and continue to the dashboard
      </p>
      {alertDialog}
      <div className="auth-credentials m-b-xxl">
        <Form ref={refFrom}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              onChange={(e) => setUserName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Form.Group>
        </Form>
      </div>
      <div className="auth-submit">
        <Button
          variant="outline-primary"
          style={{ width: "100%" }}
          onClick={submitHandler}
        >
          SIGN IN
        </Button>
        <Button
          variant="outline-primary"
          style={{ width: "100%", marginTop: "5%" }}
          onClick={logIn}
        >
          <svg
            width="22"
            height="22"
            fill="currentColor"
            className="bi bi-google"
            viewBox="0 0 16 16"
            style={{ paddingBottom: "4px" }}
          >
            <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"></path>
          </svg>{" "}
          SIGN IN WITH GOOGLE
        </Button>
      </div>
    </PageLayout>
  );
}

export default SignIn;
