import { auth } from "./AuthService";
import { user } from "./UserService";
import { pageMenu } from "./PageService";
import { master } from "./master";
import { list } from "./list";
import { home } from "./home";

const Service = {
  auth,
  user,
  pageMenu,
  master,
  list,
  home,
};

export { Service };
