import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneLabel = ({ title, notifyTelp, ...rest }) => {
  if (notifyTelp === undefined) notifyTelp = "";

  return (
    <Form.Group className="mb-4">
      {title !== undefined ? <Form.Label>{title}</Form.Label> : ""}
      <PhoneInput
        inputStyle={{ width: "100%" }}
        className="m-b-md"
        country={"id"}
        {...rest}
      />
      <Form.Text
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default PhoneLabel;
