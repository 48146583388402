import { useEffect, useRef, useState } from "react";
import Access from "../PageLayout/Access";
import Layout from "../PageLayout/Layout";
import { Service } from "../../utils/service";
import {
  Button,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  ToggleButton,
} from "react-bootstrap";
import Div from "../../components/Div";
import DatePickerLabel from "../../components/DatePickerLabel";
import ChartApex from "../../components/ChartApex";
import SelectLabel from "../../components/SelectLabel";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import BytesToSize from "../../utils/BytesToSize";
import Tables from "../../components/Tables";
import moment from "moment";
import "moment/locale/id";
import DateMoment from "../../utils/DateMoment";
import Icon from "react-multi-date-picker/components/icon";
import QRCode from "react-qr-code";

function Home() {
  const { actCreated, actUpdate, actDelete } = Access();
  const [loader, setLoader] = useState(false);

  const [akun, setAkun] = useState(null);
  const [optionsAkun, setOptionsAkun] = useState(null);

  const [dataAccount, setDataAkun] = useState([]);
  const [typeData, setTypeData] = useState("");
  const [modal, setModal] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [barcode, setBarcode] = useState("");

  const [dayRange, setDayRange] = useState(null);
  const [montRange, setMontRange] = useState(null);

  const [totalDay, setTotalDay] = useState("total : 0GB");
  const [totalMonth, setTotalMonth] = useState("total : 0GB");

  const [headerDay, setHeaderDay] = useState("");
  const [categoriesDay, setCategoriesDay] = useState([]);
  const [seriesDay, setSeriesDay] = useState([]);

  const [headerMonth, setHeaderMonth] = useState("");
  const [categoriesMonth, setCategoriesMonth] = useState([]);
  const [seriesMonth, setSeriesMonth] = useState([]);

  useEffect(() => {
    if (new JWToken()._getStatusJWToken()) {
      updateListData();
    }
  }, []);

  useEffect(() => {
    getDataAkun();
  }, [akun]);

  useEffect(() => {
    dayRequest();
  }, [dayRange, akun]);

  useEffect(() => {
    monthRequest();
  }, [montRange, akun]);

  async function updateListData() {
    setLoader(true);
    const payload = new FormData();
    const result = await Service.home.trafik._read(payload);
    setLoader(false);
    if (result.status) {
      setOptionsAkun(result.data);
    }
  }

  async function getDataAkun() {
    if (akun === null) {
      return;
    }
    setLoader(true);
    const payload = new FormData();
    payload.append("idXray", akun.value);
    const result = await Service.home.trafik._detail(payload);
    setLoader(false);
    if (result.status) {
      setDataAkun(result.data.dataAccount);
      setTypeData(result.data.typeData);
    }
  }

  async function dayRequest() {
    if (akun === null || dayRange === null) {
      return;
    }
    setLoader(true);
    const payload = new FormData();
    payload.append("idXray", akun.value);
    payload.append("dateFrom", dayRange[0]);
    payload.append("dateTo", dayRange[1]);
    const result = await Service.home.trafik._day(payload);
    setLoader(false);
    if (result.status) {
      setHeaderDay(result.data.header);
      setCategoriesDay(result.data.categories);
      setSeriesDay(result.data.series);
      const categories = result.data.categories;
      if (categories.length <= 0) {
        new Swalert().warning("data tidak ada untuk di tampilkan");
      }
      const arrData = result.data.series;
      var totalData = 0;
      for (let index = 0; index < arrData[0].data.length; index++) {
        const gb = arrData[0].data[index];
        totalData = totalData + parseFloat(gb);
      }
      setTotalDay("total : " + BytesToSize(totalData));
    }
  }
  async function monthRequest() {
    if (akun === null || montRange === null) {
      return;
    }
    setLoader(true);
    const payload = new FormData();
    payload.append("idXray", akun.value);
    payload.append("dateFrom", montRange[0]);
    payload.append("dateTo", montRange[1]);
    const result = await Service.home.trafik._month(payload);
    setLoader(false);
    if (result.status) {
      setHeaderMonth(result.data.header);
      setCategoriesMonth(result.data.categories);
      setSeriesMonth(result.data.series);
      const categories = result.data.categories;
      if (categories.length <= 0) {
        new Swalert().warning("data tidak ada untuk di tampilkan");
      }
      const arrData = result.data.series;
      var totalData = 0;
      for (let index = 0; index < arrData[0].data.length; index++) {
        const gb = arrData[0].data[index];
        totalData = totalData + parseFloat(gb);
      }
      setTotalMonth("total : " + BytesToSize(totalData));
    }
  }

  function setLayoutModal() {
    return (
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <b>{headerModal}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <QRCode value={barcode} size={300} />
          </Modal.Body>
        </Div>
      </Modal>
    );
  }
  function setLayoutTable() {
    function copyClipboard(item) {
      navigator.clipboard.writeText(item.config);
      alert("Success copied " + item.name + " to clipboard");
    }
    function showBarcode(item) {
      setHeaderModal(item.name);
      setBarcode(item.config);
      setModal(true);
    }

    function action(item) {
      return (
        <ButtonGroup>
          <ToggleButton
            variant={"outline-primary"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => copyClipboard(item)}
          >
            COPY
          </ToggleButton>
          <ToggleButton
            variant={"outline-success"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => showBarcode(item)}
          >
            SCAN
          </ToggleButton>
        </ButtonGroup>
      );
    }

    function setBodyAkun() {
      return dataAccount.map((item) => {
        if (item.name === "Last Online") {
          item.value = DateMoment.dateTo(item.value);
        }
        if (item.name === "Remote Online") {
          let date = moment(item.value);
          item.value = DateMoment.dateTo(date);
        }
        if (item.name === "Usage Quota") {
          item.value = BytesToSize(item.value);
        }
        if (item.name === "Limit Quota") {
          item.value = BytesToSize(item.value);
        }

        if (item.type === "text") {
          return (
            <Tables.Tr>
              <Tables.Td>{item.name}</Tables.Td>
              <Tables.Td>{item.value}</Tables.Td>
            </Tables.Tr>
          );
        }
      });
    }
    function setBodyDetail() {
      return dataAccount.map((item) => {
        if (item.type !== "text") {
          return item.dataConfig.map((config) => {
            return (
              <Tables.Tr>
                <Tables.Td>{config.name}</Tables.Td>
                <Tables.Td>{action(config)}</Tables.Td>
              </Tables.Tr>
            );
          });
        }
      });
    }
    return (
      <>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th colspan={"2"}>DETAIL AKUN</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{setBodyAkun()}</Tables.Body>
        </Tables.Table>
        {typeData !== "xray" ? (
          ""
        ) : (
          <Tables.Table>
            <Tables.Head>
              <Tables.Tr>
                <Tables.Th colspan={"2"}>DETAIL CONFIG</Tables.Th>
              </Tables.Tr>
            </Tables.Head>
            <Tables.Body>{setBodyDetail()}</Tables.Body>
          </Tables.Table>
        )}
      </>
    );
  }
  function layoutAfterSetAkun() {
    return (
      <Tabs defaultActiveKey="0" transition={true}>
        <Tab eventKey="0" title="STATUS">
          <Div className="mt-4">{setLayoutTable()}</Div>
        </Tab>
        <Tab eventKey="1" title="HARIAN">
          <Div className="mt-4">
            <DatePickerLabel
              range={true}
              onChange={setDayRange}
              title="Pilih Rentang Tanggal"
            />
            <Div>{totalDay}</Div>
          </Div>
          <ChartApex
            byte={true}
            header={headerDay}
            categories={categoriesDay}
            series={seriesDay}
          />
        </Tab>
        <Tab eventKey="2" title="BULANAN">
          <Div className="mt-4">
            <DatePickerLabel
              range={true}
              onlyMonthPicker={true}
              onChange={setMontRange}
              title="Pilih Rentang Bulan"
            />
            <Div>{totalMonth}</Div>
          </Div>
          <ChartApex
            byte={true}
            header={headerMonth}
            categories={categoriesMonth}
            series={seriesMonth}
          />
        </Tab>
      </Tabs>
    );
  }
  return (
    <Layout loader={loader}>
      <Div style={{ margin: "5px" }}>
        <SelectLabel
          title={"Pilih Akun"}
          isSearchable={false}
          onChange={(e) => {
            setAkun(e);
          }}
          options={optionsAkun}
          getOptionLabel={(item) =>
            `${item.zerotier === null ? "Xray" : "Inject"} : ${item.label}`
          }
        />

        {akun === null ? "" : layoutAfterSetAkun()}
      </Div>
      {setLayoutModal()}
    </Layout>
  );
}
export default Home;
